import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import React from 'react';
import formatters from '../../../Core/Grid/inputFormatter';
import './ReportingScreensStyle.scss';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from 'react-router-dom';
import { Bar, BarChart, CartesianGrid, Cell, LabelList, Legend, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { RenderTiles } from '../../customer/CustCommonDash';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import DepartureBoardIcon from '@material-ui/icons/DepartureBoard';
import TimerIcon from '@material-ui/icons/Timer';
import { WIPLineCharts } from './WIpHours/WipLineChart';

import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
    },
    bar: {
        borderRadius: 5,

        backgroundColor: (props) => {
            let bgColor = props.value < 50 ? 'red' : props.value >= 50 && props.value < 75 ? '#FFBF00' : 'green';

            return bgColor;
        }
    }
}))(LinearProgress);

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <BorderLinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(props.remainingValue)}`}</Typography>
            </Box>
        </Box>
    );
}

function isFiniteCheck(str) {
    return isFinite(str) ? str.toFixed(2) : '0.00';
}

var colorCombi = {
    0: { succ: '#abeaab', fail: '#e58f87' },
    1: { succ: '#188f18', fail: '#792222' }
};

const COLORS = {
    0: '#0074D9',
    1: '#FF4136',
    2: '#2ECC40',
    3: '#FF851B',
    4: '#7FDBFF',
    5: '#B10DC9',
    6: '#FFDC00',
    7: '#C33C9A',
    8: '#39CCCC',
    9: '#01FF70',
    10: '#85144b',
    11: '#F012BE',
    12: '#3D9970',
    13: '#111111',
    14: '#AAAAAA'
};

const minMaxYAxisCal = (dataMin, dataMax) => {
    // return [dataMin, dataMax];
    const absMax = Math.max(Math.abs(dataMin), Math.abs(dataMax));
    // if (dataMax < 0) {
    //     dataMin = dataMin + dataMin / 10;
    //     dataMax = 0 - dataMin / 10;
    // }
    // else {
    //     dataMin = dataMin + dataMin / 10;
    //     dataMax = dataMax + dataMax / 5;
    // }

    return [-absMax - absMax / 6, absMax + absMax / 6];
};

const CustomTooltip = ({ active, payload, label, formatter }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: 'white', border: '1px solid black', padding: '10px', textAlign: 'center' }}>
                <div>{label}</div>
                <div style={{ color: '#1ECBE1' }}>
                    {payload[0].name}: &nbsp;
                    {formatter ? formatters[`${formatter}`](+payload[0].value) : +payload[0].value}
                </div>
            </div>
        );
    }

    return null;
};
const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value, formatter, fill } = props;

    return (
        <text x={x + width / 2} y={value > 0 ? y - 10 : y + 10} fill={fill} textAnchor="middle" dominantBaseline="middle" fontSize={14}>
            {formatter ? formatters[`${formatter}`](value) : value}
        </text>
    );
};

const PartCustomTooltip = ({ active, payload, label, formatter }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: 'white', border: '1px solid black', padding: '10px', textAlign: 'center' }}>
                <div>{label}</div>
                <div style={{ color: '#1ECBE1' }}>
                    {payload[0].name}: &nbsp;
                    {formatter ? formatters[`${formatter}`](+payload[0].value) : +payload[0].value}
                </div>
                <div style={{ color: '#1ECBE1' }}>
                    {payload[1].name}: &nbsp;
                    {formatter ? formatters[`${formatter}`](+payload[1].value) : +payload[1].value}
                </div>
            </div>
        );
    }

    return null;
};

const renderPartsCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;

    return (
        <text
            x={x + width / 2}
            y={value > 0 ? y - 10 : y + 10}
            fill={value < 0 ? '#792222' : '#188f18'}
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize={14}
        >
            {formatters.CurrencyThousandSeparatorWithoutZero(value)}
        </text>
    );
};

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#5c5c5c',
        color: 'white',
        padding: 9,
        border: 'none',
        fontWeight: 600
    },
    body: {
        fontSize: 14,
        border: 'none',
        padding: 9,
        borderBottom: '1px solid rgba(0,0,0,0.009)'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0,0,0,0.009)'
        }
    }
}))(TableRow);

const StyledTableRowGrey = withStyles((theme) => ({
    root: {
        backgroundColor: 'lightGrey'
    }
}))(TableRow);

const useStyles = makeStyles({
    table: {
        overflow: 'auto',
        minWidth: '1400px',
        border: '1px solid rgba(0,0,0,0.002)'
    },
    surChargetable: {
        overflow: 'auto',
        minWidth: '100%',
        border: '1px solid rgba(0,0,0,0.002)'
    }
});

export const VehicleCard = (props) => {
    const { row, MTD, YTD, label, BudgetMTD, BudgetYTD } = props;

    const classes = useStyles();
    return (
        <Grid container className="kpibox" spacing={2}>
            <Grid item xs={12}>
                <Grid container alignContent="initial" justifyContent="space-between" alignItems="left" className="cardComp">
                    <Grid item container xs={12} sm={4}>
                        <Grid item xs={12}>
                            <Typography
                                style={{
                                    fontSize: 25,
                                    backgroundColor: 'rgba(0,0,0,0.01)',
                                    padding: '0px 9px',
                                    letterSpacing: '2px',
                                    textAlign: 'left'
                                }}
                            >
                                {label}
                            </Typography>
                        </Grid>

                        <Grid item xs={6} sm={12}>
                            <Typography variant="caption"> MTD</Typography>
                            <Typography style={{ fontSize: 25, fontWeight: '600' }}>{formatters.ThousandSeparatorWithoutZero(MTD)}</Typography>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className={BudgetMTD <= MTD ? 'customChip' : 'customChipRed'}>{BudgetMTD || 0}</div>
                            </div>
                        </Grid>

                        <Grid item xs={6} sm={12}>
                            <Typography variant="caption">YTD</Typography>
                            <Typography style={{ fontSize: 25, fontWeight: '600' }}>{formatters.ThousandSeparatorWithoutZero(YTD)}</Typography>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginBottom: 10
                                }}
                            >
                                <div className={BudgetYTD <= YTD ? 'customChip' : 'customChipRed'}>{BudgetYTD || 0}</div>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid item container xs={12} sm={8}>
                        {' '}
                        <TableContainer style={{ maxHeight: 250 }}>
                            <Table className={classes.table} stickyHeader aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Franchise</StyledTableCell>
                                        <StyledTableCell align="right">MTD</StyledTableCell>
                                        <StyledTableCell align="right">YTD</StyledTableCell>
                                        {/* <StyledTableCell align="right">MTD Budget</StyledTableCell>
                    <StyledTableCell align="right">YTD Budget</StyledTableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.map((n, i) => {
                                        return (
                                            <StyledTableRow key={i}>
                                                <StyledTableCell>
                                                    <b>{n.franchiseName}</b>
                                                </StyledTableCell>
                                                <StyledTableCell align="right">{formatters.ThousandSeparatorWithoutZero(n.mtdTotal)}</StyledTableCell>
                                                <StyledTableCell align="right">{formatters.ThousandSeparatorWithoutZero(n.ytdTotal)}</StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export const StockCard = (props) => {
    const { row, label } = props;

    return (
        <Grid container className="kpibox2" spacing={2}>
            <Grid item xs={12}>
                {row.map((n) => {
                    return (
                        <Grid container alignContent="initial" justifyContent="space-between" alignItems="left" className="cardComp">
                            <Grid item container xs={12} sm={12}>
                                <Grid item xs={12}>
                                    <Typography
                                        style={{
                                            // fontWeight: "600",
                                            fontSize: 25,
                                            backgroundColor: '#B1480C',
                                            letterSpacing: '2px',
                                            padding: '0px 9px',
                                            color: 'white'
                                        }}
                                    >
                                        {label}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item container alignContent="center" style={{ padding: 10 }} xs={12} sm={3}>
                                <Grid item xs={6}>
                                    <Typography variant="caption">Current Stock Value</Typography>
                                    <Typography style={{ fontSize: 25, fontWeight: '600' }}>
                                        {formatters.CurrencyThousandSeparatorWithoutZero(n.currentStockTotal)}
                                    </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant="caption">Average Stock Value</Typography>
                                    <Typography style={{ fontSize: 25, fontWeight: '600' }}>
                                        {formatters.CurrencyThousandSeparatorWithoutZero(n.currentStockTotal / n.currentStockCount)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item container style={{ padding: 10 }} xs={12} sm={9}>
                                <Grid item xs={4} md={3} lg={2}>
                                    <Grid container spacing={1} direction="column" alignItems="center">
                                        <Grid item className="AVStype3_Text">
                                            0 - 30 DAYS
                                        </Grid>
                                        <Grid item className="AVStype1_Text">
                                            {formatters.CurrencyThousandSeparatorWithoutZero(n.total0to30)}
                                        </Grid>
                                        <Grid item className="AVStype3_Text">
                                            {formatters.ThousandSeparatorWithoutZero(n.count0to30)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} md={3} lg={2}>
                                    <Grid container spacing={1} direction="column" alignItems="center">
                                        <Grid item className="AVStype3_Text">
                                            31 -60 DAYS
                                        </Grid>
                                        <Grid item className="AVStype1_Text">
                                            {formatters.CurrencyThousandSeparatorWithoutZero(n.total31to60)}
                                        </Grid>
                                        <Grid item className="AVStype3_Text">
                                            {formatters.ThousandSeparatorWithoutZero(n.count31to60)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} md={3} lg={2}>
                                    <Grid container spacing={1} direction="column" alignItems="center">
                                        <Grid item className="AVStype3_Text">
                                            61 - 90 DAYS
                                        </Grid>
                                        <Grid item className="AVStype1_Text">
                                            {formatters.CurrencyThousandSeparatorWithoutZero(n.total61to90)}
                                        </Grid>
                                        <Grid item className="AVStype3_Text">
                                            {formatters.ThousandSeparatorWithoutZero(n.count61to90)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} md={3} lg={2}>
                                    <Grid container spacing={1} direction="column" alignItems="center">
                                        <Grid item className="AVStype3_Text">
                                            91 - 120 DAYS
                                        </Grid>
                                        <Grid item className="AVStype1_Text">
                                            {formatters.CurrencyThousandSeparatorWithoutZero(n.total91to120)}
                                        </Grid>
                                        <Grid item className="AVStype3_Text">
                                            {formatters.ThousandSeparatorWithoutZero(n.count91to120)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} md={3} lg={2}>
                                    <Grid container spacing={1} direction="column" alignItems="center">
                                        <Grid item className="AVStype3_Text">
                                            121 - 150 DAYS
                                        </Grid>
                                        <Grid item className="AVStype1_Text">
                                            {formatters.CurrencyThousandSeparatorWithoutZero(n.total121to150)}
                                        </Grid>
                                        <Grid item className="AVStype3_Text">
                                            {formatters.ThousandSeparatorWithoutZero(n.count121to150)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} md={3} lg={2}>
                                    <Grid container spacing={1} direction="column" alignItems="center">
                                        <Grid item className="AVStype3_Text">
                                            150+ DAYS
                                        </Grid>
                                        <Grid item className="AVStype1_Text">
                                            {formatters.CurrencyThousandSeparatorWithoutZero(n.total150Plus)}
                                        </Grid>
                                        <Grid item className="AVStype3_Text">
                                            {formatters.ThousandSeparatorWithoutZero(n.count150Plus)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
};

let configIndex = [
    { name: 'MTD Hours', keyName: 'mtdSold' },
    { name: 'Hours Taken', keyName: 'mtdTaken' },
    { name: 'Available Hours', keyName: 'ytdAvailable' },
    { name: 'YTD Hours', keyName: 'ytdSold' },
    { name: 'YTD Taken', keyName: 'ytdTaken' }
];
let configIndex2 = [
    { name: 'MTD Sold', keyName: 'mtdSale' },
    { name: 'YTD Sold', keyName: 'ytdSale' }
];

export const AfterSaleCombined = (props) => {
    const classes = useStyles();
    const { row, label } = props;
    const history = useHistory();

    props.list.forEach((o, i) => {
        configIndex.forEach((p) => {
            p[`${o.bn}`] = o[p.keyName];
        });
        configIndex2.forEach((p) => {
            p[`${o.bn}`] = o[p.keyName];
        });
    });

    const GoToDailyInvoicedHours = (params) => {
        let searchQuery = `startDateType=${params?.startDateType || ''}&branchID=${params?.branchId || ''}`;
        history.push({
            pathname: `/Services/InvoicedHours`,
            search: searchQuery
        });
    };

    return (
        <Grid container className="kpibox2">
            <Grid item xs={12}>
                <Grid container alignContent="initial" justifyContent="space-between" alignItems="left" className="cardComp">
                    <Grid item xs={12}>
                        <Typography
                            style={{
                                fontSize: 25,
                                backgroundColor: 'rgba(0,0,0,0.01)',
                                padding: '0px 9px',
                                letterSpacing: '2px',
                                textAlign: 'left'
                            }}
                        >
                            {label}
                        </Typography>
                    </Grid>
                    {props.isHoursSoldBudgetData ? (
                        <>
                            <Grid item container xs={12} md={6} lg={3} justifyContent="space-around">
                                <Grid item xs={6} sm={4} md={4}>
                                    <Typography
                                        style={{
                                            fontSize: 16,
                                            fontWeight: '600',
                                            textAlign: 'left',
                                            marginBottom: 10
                                        }}
                                    >
                                        Sold Hours
                                    </Typography>
                                    <Typography style={{ fontSize: 11, textAlign: 'left' }}>MTD</Typography>

                                    <Typography
                                        style={{ fontSize: 25, fontWeight: '600', textAlign: 'left', cursor: 'pointer' }}
                                        onClick={() => GoToDailyInvoicedHours({ startDateType: 'mtd' })}
                                    >
                                        {formatters.ThousandSeparatorWithoutZero(props.sold)}
                                    </Typography>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start'
                                        }}
                                    >
                                        <div className={props.MTDTotalBudget <= props.sold ? 'customChip' : 'customChipRed'}>
                                            {formatters.ThousandSeparatorWithoutZero(props.MTDTotalBudget) || 0}
                                        </div>
                                    </div>
                                    <br />
                                    <Typography style={{ fontSize: 11, textAlign: 'left' }}>YTD</Typography>
                                    <Typography
                                        style={{ fontSize: 25, fontWeight: '600', textAlign: 'left', cursor: 'pointer' }}
                                        onClick={() => GoToDailyInvoicedHours({ startDateType: 'ytd' })}
                                    >
                                        {formatters.ThousandSeparatorWithoutZero(props.YTDsold)}
                                    </Typography>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        <div className={props.YTDTotalBudget <= props.YTDsold ? 'customChip' : 'customChipRed'}>
                                            {formatters.ThousandSeparatorWithoutZero(props.YTDTotalBudget) || 0}
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={6} sm={4} md={4}>
                                    <Typography
                                        style={{
                                            fontSize: 16,
                                            fontWeight: '600',
                                            textAlign: 'left',
                                            marginBottom: 10
                                        }}
                                    >
                                        Sales
                                    </Typography>
                                    <Typography style={{ fontSize: 11, textAlign: 'left' }}>MTD</Typography>
                                    <Typography
                                        style={{ fontSize: 25, fontWeight: '600', textAlign: 'left', cursor: 'pointer' }}
                                        onClick={() => GoToDailyInvoicedHours({ startDateType: 'mtd' })}
                                    >
                                        {formatters.CurrencyThousandSeparatorWithoutZero(props.sale)}
                                    </Typography>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        <div className={props.mtdBudget <= props.sale ? 'customChip' : 'customChipRed'}>
                                            {formatters.CurrencyThousandSeparatorWithoutZero(props.mtdBudget) || 0}
                                        </div>
                                    </div>
                                    <br />
                                    <Typography style={{ fontSize: 11, textAlign: 'left' }}>YTD</Typography>
                                    <Typography
                                        style={{ fontSize: 25, fontWeight: '600', textAlign: 'left', cursor: 'pointer' }}
                                        onClick={() => GoToDailyInvoicedHours({ startDateType: 'ytd' })}
                                    >
                                        {formatters.CurrencyThousandSeparatorWithoutZero(props.YTDsale)}
                                    </Typography>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        <div className={props.ytdBudget <= props.YTDsale ? 'customChip' : 'customChipRed'}>
                                            {formatters.CurrencyThousandSeparatorWithoutZero(props.ytdBudget) || 0}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={1}></Grid>
                            </Grid>

                            <Grid item container xs={12} md={6} lg={3} justifyContent="space-around">
                                <Grid item xs={12}>
                                    <ResponsiveContainer width="100%" height={300}>
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={configIndex2}
                                            margin={{
                                                top: 5,
                                                right: 0,
                                                left: 10,
                                                bottom: 5
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" style={{ fontSize: 10 }} />
                                            <YAxis style={{ fontSize: 10 }} tickFormatter={(value) => value.toFixed(2)} />
                                            <Tooltip />
                                            <Legend />
                                            <ReferenceLine y={0} stroke="#000" />

                                            {props.list.map((p, i) => {
                                                return (
                                                    <Bar
                                                        dataKey={`${p.bn}`}
                                                        fill={COLORS[i]}
                                                        stackId={`${p.name}`}
                                                        // name={`${w.name}`}
                                                        maxBarSize={20}
                                                    >
                                                        {props.list.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} />
                                                        ))}
                                                    </Bar>
                                                );
                                            })}
                                        </BarChart>
                                    </ResponsiveContainer>
                                </Grid>
                            </Grid>

                            <Grid item container xs={12} md={12} lg={6} justifyContent="space-around">
                                <Grid item xs={12}>
                                    {/* <Typography variant="h6">Dummy</Typography> */}
                                    <ResponsiveContainer width="100%" height={300}>
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={configIndex}
                                            margin={{
                                                top: 5,
                                                right: 0,
                                                left: 10,
                                                bottom: 5
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" style={{ fontSize: 10 }} />
                                            <YAxis style={{ fontSize: 10 }} tickFormatter={(value) => value.toFixed(2)} />
                                            <Tooltip />
                                            <Legend />
                                            <ReferenceLine y={0} stroke="#000" />

                                            {props.list.map((p, i) => {
                                                return (
                                                    <Bar
                                                        dataKey={`${p.bn}`}
                                                        fill={COLORS[i]}
                                                        stackId={`${p.name}`}
                                                        // name={`${w.name}`}
                                                        maxBarSize={20}
                                                    >
                                                        {props.list.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} />
                                                        ))}
                                                    </Bar>
                                                );
                                            })}
                                        </BarChart>
                                    </ResponsiveContainer>
                                </Grid>
                            </Grid>

                            <Grid item container xs={12} md={12} lg={12}>
                                <Grid item xs={12}>
                                    <TableContainer>
                                        <Table className={classes.table} stickyHeader aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell colSpan={7} align="center">
                                                        Bridge the Gap 
                                                    </StyledTableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <StyledTableCell width="50"></StyledTableCell>
                                                    <StyledTableCell align="center" width="300">
                                                        MTD Hours
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" width="420">
                                                        MTD Sold
                                                    </StyledTableCell>
                                                    {/* <StyledTableCell align="right">Hours Taken</StyledTableCell> */}
                                                    <StyledTableCell align="right">Available Hours</StyledTableCell>
                                                    <StyledTableCell align="right">Recovery Rate</StyledTableCell>
                                                    {/* <StyledTableCell align="right">MTD Cost</StyledTableCell> */}
                                                    <StyledTableCell align="right">YTD Hours</StyledTableCell>
                                                    <StyledTableCell align="right">YTD Sold</StyledTableCell>
                                                    {/* <StyledTableCell align="right">YTD Taken</StyledTableCell> */}
                                                    {/* <StyledTableCell align="right">YTD Cost</StyledTableCell> */}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {props?.list.map((n, i) => {
                                                    return (
                                                        <StyledTableRow key={i}>
                                                            <StyledTableCell
                                                                onClick={() => GoToDailyInvoicedHours({ branchId: n.branchID })}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <b>{n.bn}</b>
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                align="center"
                                                                onClick={() => GoToDailyInvoicedHours({ branchId: n.branchID, startDateType: 'mtd' })}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                                                                    <Grid item xs={3}>
                                                                        {formatters.ThousandSeparatorWithoutZero(n.mtdSold)}
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <abbr title={`${Math.round(n.monthHoursBudgetPercentage)}%`}>
                                                                            <BorderLinearProgress variant="determinate" value={n.monthHoursBudgetPercentage} />
                                                                        </abbr>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        {
                                                                            n.monthHoursBudgetDiff > 0
                                                                                ? `+${n.monthHoursBudgetDiff}`
                                                                                : `${n.monthHoursBudgetDiff}`
                                                                            // formatters.ThousandSeparatorWithoutZero(n.invoicingHours)
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                align="center"
                                                                onClick={() => GoToDailyInvoicedHours({ branchId: n.branchID, startDateType: 'mtd' })}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                {/* {formatters.CurrencyThousandSeparatorWithoutZero(n.mtdSale)} */}
                                                                <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                                                                    <Grid item xs={4}>
                                                                        {formatters.CurrencyThousandSeparator(n.mtdSale)}
                                                                    </Grid>
                                                                    <Grid item xs>
                                                                        <abbr title={`${Math.round(n.monthInvoicingPoundPercentage)}%`}>
                                                                            <BorderLinearProgress
                                                                                variant="determinate"
                                                                                value={n.monthInvoicingPoundPercentage}
                                                                            />
                                                                        </abbr>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        {n.monthInvoicingPoundDiff > 0
                                                                            ? `+${formatters.CurrencyThousandSeparator(n.monthInvoicingPoundDiff)}`
                                                                            : `${formatters.CurrencyThousandSeparator(n.monthInvoicingPoundDiff)}`}
                                                                    </Grid>
                                                                </Grid>
                                                            </StyledTableCell>
                                                            {/* <StyledTableCell
                                                                align="right"
                                                                onClick={() => GoToDailyInvoicedHours({ branchId: n.branchID, startDateType: 'mtd' })}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                {formatters.ThousandSeparatorWithoutZero(n.mtdTaken)}
                                                            </StyledTableCell> */}
                                                            <StyledTableCell align="right">
                                                                {formatters.ThousandSeparatorWithoutZero(n.ytdAvailable)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="right">
                                                                {formatters.CurrencyThousandSeparator(n.recRateTable)}
                                                            </StyledTableCell>

                                                            <StyledTableCell
                                                                align="right"
                                                                onClick={() => GoToDailyInvoicedHours({ branchId: n.branchID, startDateType: 'ytd' })}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                {formatters.ThousandSeparatorWithoutZero(n.ytdSold)}
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                align="right"
                                                                onClick={() => GoToDailyInvoicedHours({ branchId: n.branchID, startDateType: 'ytd' })}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                {formatters.CurrencyThousandSeparatorWithoutZero(n.ytdSale)}
                                                            </StyledTableCell>
                                                            {/* <StyledTableCell
                                                                align="right"
                                                                onClick={() => GoToDailyInvoicedHours({ branchId: n.branchID, startDateType: 'ytd' })}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                {formatters.ThousandSeparatorWithoutZero(n.ytdTaken)}
                                                            </StyledTableCell> */}
                                                        </StyledTableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>

                            <Grid item xs>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} lg={3}>
                                        <Typography variant="h6">Invoicing Hours</Typography>
                                        <ResponsiveContainer width="100%" height={300}>
                                            <BarChart
                                                width={500}
                                                height={300}
                                                data={props.list}
                                                margin={{
                                                    top: 5,
                                                    right: 0,
                                                    left: -20,
                                                    bottom: 5
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="bn" style={{ fontSize: 10 }} />
                                                <YAxis
                                                    style={{ fontSize: 10 }}
                                                    domain={([dataMin, dataMax]) => minMaxYAxisCal(dataMin, dataMax)}
                                                    tickFormatter={(value) => value.toFixed(2)}
                                                />
                                                <Tooltip content={<CustomTooltip formatter="ThousandSeparatorWithoutZero" />} />

                                                <ReferenceLine y={0} stroke="#000" />

                                                <Bar dataKey="invoicingHours" fill="#8884d8" name="Invoicing Hours" maxBarSize={20}>
                                                    <LabelList
                                                        dataKey={`invoicingHours`}
                                                        content={(props) => renderCustomizedLabel({ ...props, formatter: 'ThousandSeparatorWithoutZero' })}
                                                    />

                                                    {props.list.map((entry, index) => (
                                                        <Cell
                                                            key={`cell-${index}`}
                                                            fill={entry.invoicingHours < 0 ? 'red' : 'green'}
                                                            cursor="pointer"
                                                            onClick={() => GoToDailyInvoicedHours({ branchId: entry.branchID })}
                                                        />
                                                    ))}
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Grid>

                                    <Grid item xs={12} sm={6} lg={3}>
                                        <Typography variant="h6">
                                            Invoicing
                                            <span style={{ fontSize: '14px', marginLeft: '5px' }}>(£'s)</span>
                                        </Typography>
                                        <ResponsiveContainer width="100%" height={300}>
                                            <BarChart
                                                width={500}
                                                height={300}
                                                data={props.list}
                                                margin={{
                                                    top: 5,
                                                    right: 0,
                                                    left: -10,
                                                    bottom: 5
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="bn" style={{ fontSize: 10 }} />
                                                <YAxis
                                                    style={{ fontSize: 10 }}
                                                    domain={([dataMin, dataMax]) => minMaxYAxisCal(dataMin, dataMax)}
                                                    tickFormatter={(value) => value.toFixed(2)}
                                                />
                                                <Tooltip content={<CustomTooltip formatter="CurrencyThousandSeparatorWithoutZero" />} />

                                                <ReferenceLine y={0} stroke="#000" />

                                                <Bar dataKey="invoicingPound" fill="#8884d8" name="Invoicing" maxBarSize={20}>
                                                    <LabelList
                                                        dataKey={`invoicingPound`}
                                                        content={(props) =>
                                                            renderCustomizedLabel({ ...props, formatter: 'CurrencyThousandSeparatorWithoutZero' })
                                                        }
                                                    />
                                                    {props.list.map((entry, index) => (
                                                        <Cell
                                                            key={`cell-${index}`}
                                                            fill={entry.invoicingPound < 0 ? 'red' : 'green'}
                                                            cursor="pointer"
                                                            onClick={() => GoToDailyInvoicedHours({ branchId: entry.branchID })}
                                                        />
                                                    ))}
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Grid>

                                    <Grid item xs={12} sm={6} lg={3}>
                                        <Typography variant="h6">Recovery Rate</Typography>
                                        <ResponsiveContainer width="100%" height={300}>
                                            <BarChart
                                                width={500}
                                                height={300}
                                                data={props.list}
                                                margin={{
                                                    top: 5,
                                                    right: 0,
                                                    left: -20,
                                                    bottom: 5
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="bn" style={{ fontSize: 10 }} />
                                                <YAxis
                                                    style={{ fontSize: 10 }}
                                                    domain={([dataMin, dataMax]) => minMaxYAxisCal(dataMin, dataMax)}
                                                    tickFormatter={(value) => value.toFixed(2)}
                                                />
                                                <Tooltip content={<CustomTooltip formatter="CurrencyThousandSeparator" />} />

                                                <ReferenceLine y={0} stroke="#000" />

                                                <Bar dataKey="RecoveryRate" fill="#8884d8" name="Recovery Rate" maxBarSize={20}>
                                                    <LabelList
                                                        dataKey={`RecoveryRate`}
                                                        content={(props) => renderCustomizedLabel({ ...props, formatter: 'CurrencyThousandSeparator' })}
                                                        position="top"
                                                    />
                                                    {props.list.map((entry, index) => (
                                                        <Cell
                                                            key={`cell-${index}`}
                                                            fill={entry.RecoveryRate < 0 ? 'red' : 'green'}
                                                            cursor="pointer"
                                                            onClick={() => GoToDailyInvoicedHours({ branchId: entry.branchID })}
                                                        />
                                                    ))}
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Grid>

                                    <Grid item xs={12} sm={6} lg={3}>
                                        <Typography variant="h6">
                                            Efficiency
                                            <span style={{ fontSize: '14px', marginLeft: '5px' }}>(Sold/Taken hrs)</span>
                                        </Typography>
                                        <ResponsiveContainer width="100%" height={300}>
                                            <BarChart
                                                width={500}
                                                height={300}
                                                data={props.list}
                                                margin={{
                                                    top: 5,
                                                    right: 0,
                                                    left: -20,
                                                    bottom: 5
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="bn" style={{ fontSize: 10 }} />
                                                <YAxis
                                                    style={{ fontSize: 10 }}
                                                    domain={([dataMin, dataMax]) => minMaxYAxisCal(dataMin, dataMax)}
                                                    tickFormatter={(value) => value.toFixed(2)}
                                                />
                                                <Tooltip content={<CustomTooltip />} />

                                                <ReferenceLine y={0} stroke="#000" />

                                                <Bar
                                                    dataKey="efficiencyBud"
                                                    fill="#8884d8"
                                                    // label={{ position: 'top', fontSize: 10 }}
                                                    name="Sold / Taken Hrs"
                                                    maxBarSize={20}
                                                >
                                                    <LabelList
                                                        dataKey={`efficiencyBud`}
                                                        content={(props) => renderCustomizedLabel({ ...props, formatter: 'PercentageFormatter' })}
                                                    />
                                                    {props.list.map((entry, index) => (
                                                        <Cell
                                                            key={`cell-${index}`}
                                                            fill={entry.efficiencyBud < 0 ? 'red' : 'green'}
                                                            cursor="pointer"
                                                            onClick={() => GoToDailyInvoicedHours({ branchId: entry.branchID })}
                                                        />
                                                    ))}
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <Grid
                            item
                            xs={12}
                            style={{
                                height: '200px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            No records found
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export const AfterSaleBranchCardPart = (props) => {
    const history = useHistory();

    const onRedirect = (params) => {
        let searchQuery = `desc=Total&code=MT&startDateType=${params?.startDateType || ''}&branchID=${params?.branchId || ''}&dc=${params?.dc || ''}`;
        history.push({
            pathname: 'Total',
            search: searchQuery
        });
    };

    const classes = useStyles();
    const { row, label } = props;

    return (
        <Grid container className="kpibox2">
            <Grid item xs={12}>
                <Grid container alignContent="initial" justifyContent="space-between" alignItems="left" className="cardComp">
                    <Grid item xs={12}>
                        <Typography
                            style={{
                                fontSize: 25,
                                backgroundColor: 'rgba(0,0,0,0.01)',
                                padding: '0px 9px',
                                letterSpacing: '2px',
                                textAlign: 'left'
                            }}
                        >
                            {label}
                        </Typography>
                    </Grid>
                    {props.isPartData ? (
                        <>
                            <Grid item xs={12} md={6}>
                                <Grid container justifyContent="space-evenly">
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid xs={12}>
                                                <Typography
                                                    style={{
                                                        fontSize: 16,
                                                        fontWeight: '600',
                                                        textAlign: 'left',
                                                        marginBottom: 10
                                                    }}
                                                >
                                                    Group Sales
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            {props.partsCalculatedList.map((item, i) => {
                                                return (
                                                    <Grid item xs>
                                                        <Typography style={{ fontSize: 11, textAlign: 'left' }}>MTD {item.d}</Typography>

                                                        <Typography
                                                            onClick={() => onRedirect({ startDateType: 'mtd', dc: item.dc })}
                                                            style={{
                                                                fontSize: 20,
                                                                fontWeight: '600',
                                                                cursor: 'pointer',
                                                                textAlign: 'left'
                                                            }}
                                                        >
                                                            {formatters.CurrencyThousandSeparatorWithoutZero(item.mtdSaleValue)}
                                                            {!item.d ? <span style={{ fontSize: '12px' }}>({isFiniteCheck(item.mtdgpp)}%)</span> : null}
                                                        </Typography>
                                                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                            <div className={item.mtdBudget <= item.mtdSaleValue ? 'customChip' : 'customChipRed'}>
                                                                {formatters.CurrencyThousandSeparatorWithoutZero(item.mtdBudget) || 0}
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                        <Grid container spacing={2}>
                                            {props.partsCalculatedList.map((item, i) => {
                                                return (
                                                    <Grid item xs>
                                                        <Typography style={{ fontSize: 11, textAlign: 'left' }}>YTD {item.d}</Typography>

                                                        <Typography
                                                            onClick={() => onRedirect({ startDateType: 'ytd', dc: item.dc })}
                                                            style={{
                                                                fontSize: 20,
                                                                fontWeight: '600',
                                                                cursor: 'pointer',
                                                                textAlign: 'left'
                                                            }}
                                                        >
                                                            {formatters.CurrencyThousandSeparatorWithoutZero(item.ytdSaleValue)}
                                                            {!item.d ? <span style={{ fontSize: '12px' }}>({isFiniteCheck(item.ytdgpp)}%)</span> : null}
                                                        </Typography>
                                                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                            <div className={item.ytdBudget <= item.ytdSaleValue ? 'customChip' : 'customChipRed'}>
                                                                {formatters.CurrencyThousandSeparatorWithoutZero(item.ytdBudget) || 0}
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6">Parts Sales Variance</Typography>
                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={props.graphData.list}
                                        margin={{
                                            top: 5,
                                            right: 0,
                                            left: 0,
                                            bottom: 5
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="bn" style={{ fontSize: 10 }} />
                                        <YAxis
                                            style={{ fontSize: 10 }}
                                            tickFormatter={(value) => value.toFixed(2)}
                                            domain={([dataMin, dataMax]) => minMaxYAxisCal(dataMin, dataMax)}
                                        />
                                        <Tooltip content={<PartCustomTooltip formatter="CurrencyThousandSeparatorWithoutZero" />} />

                                        <ReferenceLine y={0} stroke="#000" />
                                        {props.graphData.uniqueKeys.map((q, i) => {
                                            return (
                                                <Bar dataKey={`${q.code}`} name={`${q.name}`} maxBarSize={20}>
                                                    <LabelList dataKey={`${q.code}`} content={renderPartsCustomizedLabel} />
                                                    {props.graphData.list.map((entry, index) => {
                                                        return (
                                                            <Cell
                                                                key={`cell-${index}`}
                                                                fill={entry[q.code] > 0 ? 'green' : 'red'}
                                                                cursor="pointer"
                                                                onClick={() => onRedirect({ branchId: entry.branchID, dc: q.code })}
                                                            />
                                                        );
                                                    })}
                                                </Bar>
                                            );
                                        })}
                                    </BarChart>
                                </ResponsiveContainer>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '10px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TableContainer style={{ minHeight: 260 }}>
                                            <Table className={classes.table} stickyHeader aria-label="customized table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell width="10%" align="left">
                                                            <b></b>
                                                        </StyledTableCell>
                                                        <StyledTableCell width="5%" align="left">
                                                            <b></b>
                                                        </StyledTableCell>{' '}
                                                        {props.uniqueBranchList.map((n, i) => {
                                                            return (
                                                                <StyledTableCell width="10%" align="center">
                                                                    <div>
                                                                        <b>{n.bn}</b>
                                                                    </div>
                                                                    MTD / YTD
                                                                </StyledTableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {props.list.map((itm, i) => {
                                                        return (
                                                            <StyledTableRow>
                                                                <StyledTableCell width="10%" align="left" onClick={() => onRedirect({ dc: itm.dc })}>
                                                                    {itm.d}
                                                                </StyledTableCell>
                                                                <StyledTableCell width="5%" align="left">
                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={12}>
                                                                            Required
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            Accomplished
                                                                        </Grid>
                                                                    </Grid>
                                                                </StyledTableCell>
                                                                {itm.data.map((dataItme, dataItmIndex) => {
                                                                    return (
                                                                        <StyledTableCell width="10%" align="center">
                                                                            <Grid container spacing={2}>
                                                                                <Grid item xs={12}>
                                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(dataItme.mtdBudget)} /{' '}
                                                                                    {formatters.CurrencyThousandSeparatorWithoutZero(dataItme.ytdBudget)}
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <span
                                                                                        onClick={() =>
                                                                                            onRedirect({
                                                                                                startDateType: 'mtd',
                                                                                                dc: itm.dc,
                                                                                                branchId: dataItme.branchID
                                                                                            })
                                                                                        }
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        className={
                                                                                            dataItme.mtdSaleValue < dataItme.mtdBudget
                                                                                                ? 'customChipRed'
                                                                                                : 'customChip'
                                                                                        }
                                                                                    >
                                                                                        {formatters.CurrencyThousandSeparatorWithoutZero(dataItme.mtdSaleValue)}
                                                                                    </span>
                                                                                    {' / '}
                                                                                    <span
                                                                                        onClick={() =>
                                                                                            onRedirect({
                                                                                                startDateType: 'ytd',
                                                                                                dc: itm.dc,
                                                                                                branchId: dataItme.branchID
                                                                                            })
                                                                                        }
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        className={
                                                                                            dataItme.ytdSaleValue < dataItme.ytdBudget
                                                                                                ? 'customChipRed'
                                                                                                : 'customChip'
                                                                                        }
                                                                                    >
                                                                                        {formatters.CurrencyThousandSeparatorWithoutZero(dataItme.ytdSaleValue)}
                                                                                    </span>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </StyledTableCell>
                                                                    );
                                                                })}
                                                            </StyledTableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <Grid
                            item
                            xs={12}
                            style={{
                                height: '200px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            No records found
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export const AfterSaleSupercharge = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const onRedirect = (params) => {
        let searchQuery = `?branchID=${params.branchId}&bn=${
            params.branch
        }&measuredesc=${'Surcharges'}&MeasureID=${2}&fromPage=${'Aged Measures'}&month=${''}&unallocated=${''}`;
        history.push({
            pathname: '/AgedMeasures/GetAgedMeasuresDirtyUnits',
            search: searchQuery
        });
    };

    const { row, label } = props;
    return (
        <Grid container className="kpibox2" spacing={2}>
            <Grid item xs={12}>
                <Grid container alignContent="initial" justifyContent="space-between" alignItems="left">
                    <Grid item container xs={12} sm={12} spacing={1} className="cardComp">
                        {/* <Grid item xs={12}>
                            <Typography
                                style={{
                                    // fontWeight: "600",
                                    letterSpacing: '2px',
                                    fontSize: 25,
                                    backgroundColor: 'rgba(0,0,0,0.01)',
                                    padding: '0px 9px',
                                    // color: "red",
                                    textAlign: 'left'
                                }}
                            >
                                {label}
                            </Typography>
                        </Grid> */}

                        <Grid item xs={12}>
                            <TableContainer style={{ minHeight: 260 }}>
                                <Table className={classes.surChargetable} stickyHeader aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>
                                                {' '}
                                                <Typography
                                                    style={{
                                                        // fontWeight: "600",
                                                        letterSpacing: '2px',
                                                        fontSize: 25,
                                                        backgroundColor: 'rgba(0,0,0,0.01)',
                                                        // padding: "0px 9px",
                                                        // color: "red",
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    {label}
                                                </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell align="right">Qty</StyledTableCell>
                                            <StyledTableCell align="right">Surcharge</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    {props.list.length > 0 ? (
                                        <TableBody>
                                            {props.list.map((n, i) => {
                                                return (
                                                    <StyledTableRow key={i}>
                                                        <StyledTableCell
                                                            onClick={() => onRedirect({ branchId: n.branchID, branch: n.bsn })}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <b>{n.branch}</b>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right">{formatters.ThousandSeparatorWithoutZero(n.qty)}</StyledTableCell>
                                                        <StyledTableCell align="right">
                                                            {formatters.CurrencyThousandSeparatorWithoutZero(n.surchargevalue)}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={3} style={{ textAlign: 'center' }}>
                                                    No records found
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export const AfterSaleJobsDetails = (props) => {
    let communicatorDashList = [
        {
            id: 1,
            name: 'VOR Vehicles',
            color: '#dc3545',
            icon: <LocalShippingIcon />,
            count: props.partsVOR?.vor || 'N/A',
            url: { pathname: '/SiteVehicles', search: `?status=VOR` },
            isShowCustomer: true
        },
        {
            id: 1,
            name: 'Part Back Order, ETA > 72 hrs',
            color: '#dc3545',
            count: props.partsVOR?.partsBackOrder || 'N/A',
            icon: <GraphicEqIcon />,
            url: { pathname: '/SiteVehicles', search: `?status=PB` },
            isShowCustomer: true
        },
        {
            id: 1,
            name: 'Awaiting Collection',
            color: '#2ecc71',
            icon: <DepartureBoardIcon />,
            count: props.partsVOR?.awaitingCollection || 'N/A',
            url: { pathname: '/SiteVehicles', search: `?status=ACOLLECTION` },
            isShowCustomer: true
        },
        {
            id: 1,
            name: 'Awaiting Authority',
            color: '#3498db',
            icon: <TimerIcon />,
            count: props.partsVOR?.awaitingAuthority || 'N/A',
            url: { pathname: '/SiteVehicles', search: `?status=AUTH` },
            isShowCustomer: true
        }
    ];

    return (
        <Grid container className="kpibox2" spacing={2}>
            <Grid item xs={12}>
                <Grid container alignContent="initial" justifyContent="space-between" alignItems="left">
                    <Grid item container xs={12} sm={12} spacing={1} className="cardComp">
                        <Grid item xs={12}>
                            <Typography
                                style={{
                                    // fontWeight: "600",
                                    letterSpacing: '2px',
                                    height: '53px',
                                    fontSize: 25,
                                    backgroundColor: 'rgba(0,0,0,0.01)',
                                    padding: '0px 9px',
                                    // color: "red",
                                    textAlign: 'left'
                                }}
                            >
                                {props.label}
                            </Typography>
                        </Grid>

                        <Grid item xs style={{ padding: '6px 8px' }}>
                            <RenderTiles cards={communicatorDashList} split={true} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export const AfterSaleYtdMtdSection = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const onRedirect = (params) => {
        let searchQuery = `branchID=${params?.branchId || ''}&bn=${params.branch}`;
        history.push({
            pathname: `/AgedMeasures/WorkInProgressbyDepartement`,
            search: searchQuery
        });
    };

    console.log(props.dailyWIPsUniqueDateList, props.dailyWIPsCombinedList, 'props.dailyWIPsUniqueDateList, props.dailyWIPsCombinedList');

    const { row, label } = props;
    return (
        <Grid container className="kpibox2" spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={0} alignContent="initial" justifyContent="space-between" alignItems="left" className="cardComp">
                    <Grid item container xs={12} sm={12} spacing={1}>
                        <Grid item xs={12}>
                            {/* <Typography
                                style={{
                                    // fontWeight: "600",
                                    letterSpacing: '2px',
                                    fontSize: 25,
                                    backgroundColor: 'rgba(0,0,0,0.01)',
                                    padding: '0px 9px',
                                    // color: "red",
                                    textAlign: 'left'
                                }}
                            >
                                {label}
                            </Typography> */}
                        </Grid>
                        <Grid item xs={12}>
                            Group WIP MTD
                        </Grid>
                        {props.dailyWIPsUniqueDateList?.length > 0 ? (
                            <>
                                <Grid item xs>
                                    <WIPLineCharts tempData={props.dailyWIPsGrapgData} keys={props.dailyWIPsUniqueBranchList} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TableContainer style={{ minHeight: 260 }}>
                                        <Table className={classes.surChargetable} stickyHeader aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="left">
                                                        <b></b>
                                                    </StyledTableCell>
                                                    {props.dailyWIPsUniqueDateList.map((n, i) => {
                                                        return (
                                                            <StyledTableCell align="right">
                                                                <div>
                                                                    <b>{n.name}</b>
                                                                </div>
                                                            </StyledTableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {props.dailyWIPsCombinedList.map((n, i) => {
                                                    return (
                                                        <StyledTableRow key={i}>
                                                            <StyledTableCell
                                                                onClick={() => onRedirect({ branchId: n.branchID, branch: n.branchShortCode })}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <b>{n.branchShortCode}</b>
                                                            </StyledTableCell>
                                                            {n.data.map((q) => {
                                                                return (
                                                                    <StyledTableCell align="right">
                                                                        {formatters.ThousandSeparatorWithoutZero(q.hours)}
                                                                    </StyledTableCell>
                                                                );
                                                            })}
                                                        </StyledTableRow>
                                                    );
                                                })}
                                                <StyledTableRowGrey>
                                                    <StyledTableCell align="left">
                                                        <div>
                                                            <b>Total</b>
                                                        </div>
                                                    </StyledTableCell>
                                                    {props.dailyWIPsUniqueDateList.map((n, i) => {
                                                        return (
                                                            <StyledTableCell align="right">
                                                                <div>
                                                                    <b>{formatters.ThousandSeparatorWithoutZero(n.Total)}</b>
                                                                </div>
                                                            </StyledTableCell>
                                                        );
                                                    })}
                                                </StyledTableRowGrey>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </>
                        ) : (
                            <Grid
                                item
                                xs={12}
                                style={{
                                    height: '200px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                No Record Found
                            </Grid>
                        )}
                    </Grid>
                    <Grid item container xs={12} sm={12} spacing={1}>
                        <Grid item xs={12}>
                            Group WIP Monthly
                        </Grid>
                        {props.monthlyWIPsUniqueDateList.length > 0 ? (
                            <>
                                <Grid item xs>
                                    <WIPLineCharts tempData={props.monthlyWIPsGrapgData} keys={props.monthlyWIPsUniqueBranchList} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TableContainer style={{ minHeight: 260 }}>
                                        <Table className={classes.surChargetable} stickyHeader aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="left">
                                                        <b></b>
                                                    </StyledTableCell>
                                                    {props.monthlyWIPsUniqueDateList.map((n, i) => {
                                                        return (
                                                            <StyledTableCell align="right">
                                                                <div>
                                                                    <b>{n.name}</b>
                                                                </div>
                                                            </StyledTableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {props.monthlyWIPsCombinedList.map((n, i) => {
                                                    return (
                                                        <StyledTableRow key={i}>
                                                            <StyledTableCell
                                                                onClick={() => onRedirect({ branchId: n.branchID, branch: n.branchShortCode })}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <b>{n.branchShortCode}</b>
                                                            </StyledTableCell>
                                                            {n.data.map((q) => {
                                                                return (
                                                                    <StyledTableCell align="right">
                                                                        {formatters.ThousandSeparatorWithoutZero(q.hours)}
                                                                    </StyledTableCell>
                                                                );
                                                            })}
                                                        </StyledTableRow>
                                                    );
                                                })}
                                                <StyledTableRowGrey>
                                                    <StyledTableCell align="left">
                                                        <div>
                                                            <b>Total</b>
                                                        </div>
                                                    </StyledTableCell>
                                                    {props.monthlyWIPsUniqueDateList.map((n, i) => {
                                                        return (
                                                            <StyledTableCell align="right">
                                                                <div>
                                                                    <b>{formatters.ThousandSeparatorWithoutZero(n.Total)}</b>
                                                                </div>
                                                            </StyledTableCell>
                                                        );
                                                    })}
                                                </StyledTableRowGrey>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </>
                        ) : (
                            <Grid
                                item
                                xs={12}
                                style={{
                                    height: '200px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                No Record Found
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
