import React, { useMemo } from 'react';
import { moment } from 'moment';
import { Grid, FormLabel } from '@material-ui/core';
import BreadCrumbs from '../../../../../../Core/Controls/Breadcrumb';
import ReportGijgoGrid from '../../../GijgoForReportsScreen';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { AppStorage } from '../../../../../../Core/Service/storage-service';
import formatters from '../../../../../../Core/Grid/inputFormatter';

const getCrumbs = (menuPermissionCounter, ParamMeasureID, ParamMeasuredesc, ParamBranchName, ParamMonth, ParamUnallocated) => {
    let temp = {};
    let final = [
        { name: 'Home', path: '' }
        // { name: 'Aged Measures', active: true }
    ];
    if (menuPermissionCounter !== 1) {
        final.push({ name: 'Reports', path: '/ReportsDashboard' });
    }
    final.push({ name: 'Aged Measures', path: '/AgedMeasures' });

    if (ParamMeasureID == '2') {
        temp = { name: `${ParamMeasuredesc}`, path: `/AgedMeasures/DirtyUnits?measuredesc=${ParamMeasuredesc}&MeasureID=${ParamMeasureID}` };
    } else {
        temp = { name: `${ParamMeasuredesc}`, active: true };
    }
    final = [...final, { ...temp }];
    if (ParamBranchName) {
        temp = {
            name: `${ParamBranchName} ${ParamMonth > 0 ? `(${ParamMonth} ${ParamMonth == 1 ? 'Month' : 'Months'})` : ''} ${
                ParamUnallocated > 0 ? `(Unallocated)` : ''
            }`,
            active: true
        };
    }
    final = [...final, { ...temp }];
    return final;
};

const baseColumns = [
    { title: 'Part Number', field: 'pnp', width: 110, sortable: true },
    { title: 'Description', field: 'pd', width: 150, sortable: true },
    { title: 'Quantity', field: 'qty', width: 100, sortable: true, align: 'right' },
    {
        title: 'Surcharge',
        field: 'sv',
        width: 100,
        sortable: true,
        isCurrency: true,
        align: 'right'
    },
    {
        title: 'Sale Surcharge',
        field: 'ssv',
        width: 100,
        sortable: true,
        isCurrency: true,
        align: 'right'
    },
    { title: 'Location', field: 'bl', width: 90, sortable: true },
    {
        title: 'Last Purchased',
        field: 'dlp',
        width: 90,
        sortable: true,
        renderer: formatters.MonthShortFormatter
    },
    {
        title: 'Last Sold',
        field: 'dls',
        width: 90,
        sortable: true,
        renderer: formatters.MonthShortFormatter
    },
    {
        title: 'Last Bought',
        field: 'dlb',
        width: 90,
        sortable: true,
        renderer: formatters.MonthShortFormatter
    },
    { title: 'Months', field: 'am', width: 70, sortable: true, align: 'right' },
    { title: 'Days', field: 'ad', width: 70, sortable: true, align: 'right' }
    // { title: 'Branch', field: 'bn', width: 100, sortable: true }
];

const GetAgedMeasuresDirtyUnits = (props) => {
    let menuPermissionCounter = AppStorage.menuPermissionCounter();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let ParamMeasureID = params.get('MeasureID') || '';
    let ParamMeasuredesc = params.get('measuredesc') || '';
    let ParamMonth = params.get('month') || '';
    let ParamBranchName = params.get('bn') || '';
    let ParamBranchId = params.get('branchID') || params.get('branchId') || '';
    let ParamUnallocated = params.get('unallocated') || '';
    if (ParamMeasureID !== '2') {
        baseColumns.push({ title: 'Branch', field: 'bn', width: 100, sortable: true });
    }

    const baseUrl = `AgedMeasures/GetAgedMeasuresDirtyUnits?BranchID=${ParamBranchId}&mont=${ParamMonth}`;

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={getCrumbs(menuPermissionCounter, ParamMeasureID, ParamMeasuredesc, ParamBranchName, ParamMonth, ParamUnallocated)} />
            <Grid item container spacing={1} xs={12}>
                <Grid item xs={12}>
                    <ReportGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list,
                            total: res?.data?.total
                        })}
                        columns={baseColumns}
                        getUrl={baseUrl}
                        // isShowTotal={true}
                        // hidePagination={true}
                        // hideFilterRow={true}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default GetAgedMeasuresDirtyUnits;
