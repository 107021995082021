import React, { useEffect, useState, useMemo, useContext } from 'react';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { FormLabel, IconButton, Tooltip, Typography } from '@material-ui/core';
import { BasicButtonGroup } from '../../../Core/ButtonGroup';
import { getWorkflows } from '../../../Core/Service/workflow-service';
import AppContext from '../../../App/AppContext';

import moment from 'moment';
import './dashboard.scss';

import { makeStyles } from '@material-ui/core/styles';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';

import Grid from '@material-ui/core/Grid';
import ReactDOM from 'react-dom';
import GijgoGrid from './';
import FilterListIcon from '@material-ui/icons/FilterList';
import DialogComp from '../../../Core/Modal/dialogModal';
import GridFilterModal from './FilterModal';
import { getGridFilterList, postGridFilterList } from '../../../Core/Service/workflow-service';
import AssignTech from '../assign-technician/assignTechnModal.jsx';
import { Customi18 } from '../../../Core/Controls/i18react/customei18';
import _ from 'lodash';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LogHistory from '../Communicator/OpenActionLogs/logHistory';
import { AppStorage } from '../../../Core/Service/storage-service';
import JobPriorityModal from './jobPriorityModal';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import ReportIcon from '@material-ui/icons/Report';
import CustomerRating from './customerRating';
import StarIcon from '@material-ui/icons/Star';
import MotHistoryScreen from '../ReportsScreens/MotHistoryScreen';
import { SpeakerNotes } from '@material-ui/icons';
import JobNotesModal from '../workflow-job/JobNotes';
import { GetUserPreferenceWorkflowsList } from '../../../Core/Service/dashbordService';
import FilterModalMainTabComponent from './FilterModalMainTabComp';
import { DatePicker, MultipleSelectWithMoreOp } from '../../../Core/FormInput';
import { GetBranchDeportmentScreenDropdowns } from '../../../Core/Service/reportService';

const useStyles = (props) =>
    makeStyles((thems) => {
        return {
            ...props
        };
    });

let config = {
    // act: {
    //     from: '',
    //     to: '',
    //     getSequence: 'actionSequence',
    //     postSequence: 'actionSequence'
    // },
    wipNumber: {
        from: 'displayWIPNo',
        to: 'userGridColumnDisplayWIPNo',
        getSequence: 'wipNoSequence',
        postSequence: 'WIPNoSequence'
    },
    registration: {
        from: 'displayVehicleReg',
        to: 'userGridColumnDisplayVehicleReg',
        getSequence: 'vehicleRegSequence',
        postSequence: 'VehicleRegSequence'
    },
    makeModel: {
        from: 'displayModel',
        to: 'userGridColumnDisplayModel',
        getSequence: 'modelSequence',
        postSequence: 'modelSequence'
    },
    customerName: {
        from: 'displayCustomer',
        to: 'userGridColumnDisplayCustomer',
        getSequence: 'customerSequence',
        postSequence: 'customerSequence'
    },
    dateDueIn: {
        from: 'displayDateDueIn',
        to: 'userGridColumnDisplayDateDueIn',
        getSequence: 'dateDueInSequence',
        postSequence: 'dateDueInSequence'
    },
    dateDueOut: {
        from: 'displayDateDueOut',
        to: 'userGridColumnDisplayDateDueOut',
        getSequence: 'dateDueOutSequence',
        postSequence: 'dateDueOutSequence'
    },
    returnReason: {
        from: 'displayReturnReason',
        to: 'userGridColumnDisplayReturnReason',
        getSequence: 'returnReasonSequence',
        postSequence: 'returnReasonSequence'
    },
    branchShortName: {
        from: 'displayBranch',
        to: 'userGridColumnDisplayBranch',
        getSequence: 'branchSequence',
        postSequence: 'branchSequence'
    },
    franchiseName: {
        from: 'displayFranchise',
        to: 'userGridColumnDisplayFranchise',
        getSequence: 'franchiseSequence',
        postSequence: 'franchiseSequence'
    },
    st: {
        from: 'displayJobDescription',
        to: 'userGridColumnDisplayJobDescription',
        getSequence: 'jobDescriptionSequence',
        postSequence: 'jobDescriptionSequence'
    },
    accountNo: {
        from: 'displayAccount',
        to: 'userGridColumnDisplayAccount',
        getSequence: 'accountSequence',
        postSequence: 'accountSequence'
    },
    cb: {
        from: 'displayCreatedBy',
        to: 'userGridColumnDisplayCreatedBy',
        getSequence: 'createdBySequence',
        postSequence: 'createdBySequence'
    },
    assigned: {
        from: 'displayAssignedDate',
        to: 'userGridColumnDisplayAssignedDate',
        getSequence: 'assignedDateSequence',
        postSequence: 'assignedDateSequence'
    },
    taken: {
        from: 'displayTimeTaken',
        to: 'userGridColumnDisplayTimeTaken',
        getSequence: 'timeTakenSequence',
        postSequence: 'timeTakenSequence'
    },
    status: {
        from: 'displayStatus',
        to: 'UserGridColumnDisplayStatus',
        getSequence: 'statusSequence',
        postSequence: 'statusSequence'
    },
    assignedDays: {
        from: 'displayAssignedDays',
        to: 'UserGridColumnDisplayAssignedDays',
        getSequence: 'assignedDaysSequence',
        postSequence: 'assignedDaysSequence'
    },
    owningOperator: {
        from: 'displayOwningOperator',
        to: 'UserGridDisplayColumnOwningOperator',
        getSequence: 'owningOperatorSequence',
        postSequence: 'owningOperatorSequence'
    },
    jobAssignedUser: {
        from: 'displayJobAssignedUser',
        to: 'UserGridColumnDisplayJobAssignedUser',
        getSequence: 'jobAssignedUserSequence',
        postSequence: 'jobAssignedUserSequence'
    }
};

const isFalseValue = (val) => {
    if (val == false) {
        return false;
    } else {
        return true;
    }
};

const ratingList = {
    1: <StarIcon style={{ color: 'gold' }} size="small" />,
    2: <StarIcon style={{ color: '#C0C0C0' }} size="small" />,
    3: <StarIcon style={{ color: '#CD7F32' }} size="small" />
};

let Dashboard = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const branchIds = params.get('BranchIds');
    const branchIdsArray = branchIds?.split(',').map((id) => parseInt(id, 10));

    const [state, setState] = useState({
        workflowButtons: [],
        rows: [],
        filteredRows: [],
        totalRec: 0,
        filterText: '',
        btnSelectedId: props.location.query?.workFlowId || localStorage.getItem('currentWorkflowId'),
        currentWorkflowId: props.location.query?.workFlowId || localStorage.getItem('currentWorkflowId'),
        returnReasonID: props.location.query?.returnReasonID || '',
        hasQueryValues: props.location.query?.returnReasonID ? true : false,
        tempObj: {},
        filterList: [],
        displayPagination: true,
        showTechAssign: false,
        isReload: null,
        timeID: JSON.parse(localStorage.getItem('FiltterParams'))?.branchTime || '',
        dueTime: JSON.parse(localStorage.getItem('FiltterParams'))?.dueTime || '',
        logHistory: false,
        displayJobPriorityButton: false,
        showCustomerRating: false,
        displayCustomerRatingButton: false,
        displayCustomerMessage: false,
        UserPreferenceWorkflowsList: [],
        fromReports: params.get('fromReports') || 'No',
        startDate: params.get('startDate') || '',
        endDate: params.get('endDate') || '',
        groupIds: branchIdsArray || [],
        branchList: []
    });

    const [temp, tempOption] = useState({
        groupVal: branchIdsArray || []
    });
    // const { t, i18n, langLabel } = Customi18();
    const [loading, setLoading] = React.useState(false);
    const [WindowWidths, WindowHeights] = useWindowSize();
    const { portalSettings, getUserPreference, isAppReaload } = useContext(AppContext);
    const isCommunicatorAllowed = AppStorage.getCommunicatorAllowed() && portalSettings.portalSettingCommunicatorEnabled;
    const canSetCustomerRating = AppStorage.getCanSetCustomerRating();
    const pullGridFilterList = async () => {
        // let timeID = JSON.parse(localStorage.getItem('FiltterParams'));
        let data = await getUserPreference();
        if (data) {
            setState((st) => ({
                ...st,
                appbarLocation: data.details[0].appbarLocation || 'T',
                languageCode: data.details[0].languageCode,
                languageData: data.languages,
                tempObj: data.details[0] || {},
                displayPagination: isFalseValue(data.details[0]?.displayPagination)
                // timeID: timeID?.branchTime
            }));
        }
    };

    // useEffect(() => {
    //     if (props.location.query) {
    //         setState((st) => ({
    //             ...st,
    //             returnReasonID: props.location.query.returnReasonID,
    //             filterText: props.location.query.returnReason,
    //             btnSelectedId: props.location.query?.workFlowId,
    //             hasQueryValues: true
    //         }));
    //     }
    // }, []);

    const pullWorkFlowsButtons = async () => {
        setLoading(true);
        let res = await getWorkflows();
        if (res.success) {
            const buttons = res.data.map((w) => ({
                id: w.id,
                label: w.workflowName,
                displayJobPriorityButton: w.displayJobPriorityButton == 'Y' ? true : false,
                displayCustomerRatingButton: w.displayCustomerRatingButton == 'Y' ? true : false
            }));
            let resPreference = await GetUserPreferenceWorkflowsList();

            setState((st) => ({ ...st, workflowButtons: buttons, UserPreferenceWorkflowsList: resPreference.data.list }));
        }
    };

    useEffect(async () => {
        let res = await GetBranchDeportmentScreenDropdowns();
        if (res.success) {
            setState((st) => ({
                ...st,
                branchList: res.data?.branchList || []
            }));
        }
        pullWorkFlowsButtons();
    }, []);

    useEffect(() => {
        if (state?.branchList?.length > 0 && state?.groupIds?.length > 0) {
            const filteredBranchList = state.branchList.filter((m) => state.groupIds.includes(m.id));
            const newArray = filteredBranchList.map((p) => ({ id: p.id, name: p.name }));
            tempOption((st) => ({ ...st, groupVal: newArray }));
        } else {
            tempOption((st) => ({ ...st, groupVal: [] }));
        }
    }, [state.branchList, state.groupIds]);

    useEffect(() => {
        if (isAppReaload) {
            pullGridFilterList();
        }
    }, [isAppReaload]);

    var colorCodeObj = {};
    state.filteredRows.forEach((c) => {
        colorCodeObj[[c.id]] = {
            background: c.returnReasonColourCode,
            color: c.returnReasonTextColourCode
        };
    });
    const classes = useStyles(colorCodeObj)();

    const handleBtnClcik = (val) => {
        props.history.push(`/Job/${val.headerRecordID}/${val.workflowID}`);
    };

    const handleAssignmentBtnClick = (val) => {
        // props.history.push(`/assign-technician/${val.headerRecordID}`);

        setState((st) => ({
            ...st,
            jobId: val.headerRecordID,
            showTechAssign: true
        }));
    };

    const custerRating = (val) => {
        setState((st) => ({
            ...st,
            jobId: val.headerRecordID,
            workFlowData: val,
            showCustomerRating: true
        }));
    };

    const handleCustmer = (val) => {
        setState((st) => ({
            ...st,
            customerMessage: val,
            displayCustomerMessage: true
        }));
    };

    const handleShowJobPriorityBtnClick = (val) => {
        setState((st) => ({
            ...st,
            jobId: val.headerRecordID,
            workFlowData: val,
            showJobPriority: true
        }));
    };

    const handleLogHistory = (val) => {
        setState((st) => ({
            ...st,
            logHistory: true,
            headerRecordID: val.headerRecordID,
            reg: val.registration,
            wip: val.wipNumber
        }));
    };

    const handleJobNotes = (val, on) => {
        setState((st) => ({
            ...st,
            jobNotes: on,
            headerRecordID: val.headerRecordID,
            reg: val.registration,
            wip: val.wipNumber
        }));
    };

    const editButton = (id, val) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justify="left">
                {/* <Grid>
                    <IconButton size="small" onClick={() => handleBtnClcik(val)}>
                        <ArrowForwardIosIcon
                            style={{
                                color: val.returnReasonTextColourCode
                            }}
                            fontSize="small"
                        />
                    </IconButton>
                </Grid> */}
                {isCommunicatorAllowed && (
                    <Grid style={{ marginLeft: '13px 5px' }}>
                        <Tooltip title="Log History">
                            <IconButton size="small" onClick={() => handleLogHistory(val)}>
                                <AssignmentIcon
                                    style={{
                                        color: val.returnReasonTextColourCode
                                    }}
                                    fontSize="small"
                                />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}

                <Grid style={{ marginLeft: '13px 5px' }}>
                    <Tooltip title="Job Notes">
                        <IconButton size="small" onClick={() => handleJobNotes(val, true)}>
                            <SpeakerNotes style={{ color: val.hasNote ? 'gold' : val.returnReasonTextColourCode }} fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Grid>

                <Grid>
                    {canSetCustomerRating ? (
                        <>
                            {val.customerRatingIcon ? (
                                <Tooltip title={val.customerRatingNotes}>
                                    <IconButton size="small" onClick={() => custerRating(val)}>
                                        {ratingList[val.customerRatingIcon]}
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Set Customer Rating">
                                    <IconButton
                                        size="small"
                                        style={{
                                            color: val.returnReasonTextColourCode
                                        }}
                                        onClick={() => custerRating(val)}
                                    >
                                        <ReportIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </>
                    ) : (
                        state.displayCustomerRatingButton && (
                            <Tooltip title={val.customerRatingNotes}>
                                <IconButton
                                    size="small"
                                    style={{
                                        color: val.returnReasonTextColourCode
                                    }}
                                    onClick={() => handleCustmer(val.customerRatingNotes)}
                                >
                                    {ratingList[val.customerRatingIcon]}
                                </IconButton>
                            </Tooltip>
                        )
                    )}
                    {/* {val.customerRatingIcon ? (
                        <Tooltip title={val.customerRatingNotes}>
                            <IconButton size="small" onClick={() => handleCustmer(val.customerRatingNotes)}>
                                {ratingList[val.customerRatingIcon]}
                            </IconButton>
                        </Tooltip>
                    ) : (
                        state.displayCustomerRatingButton && (
                            <Tooltip title="Set Customer Rating">
                                <IconButton size="small" onClick={() => custerRating(val)}>
                                    <ReportIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )
                    )} */}
                </Grid>

                <Grid>
                    <Tooltip title="Set Priority">
                        <IconButton
                            size="small"
                            style={{
                                color: val.returnReasonTextColourCode
                            }}
                            onClick={state.displayJobPriorityButton ? () => handleShowJobPriorityBtnClick(val) : () => false}
                        >
                            {val.priorityNumber == 999999 && state.displayJobPriorityButton ? (
                                <LowPriorityIcon fontSize="small" />
                            ) : (
                                <>{val.priorityNumber < 999999 ? val.priorityNumber : ''}</>
                            )}
                        </IconButton>
                    </Tooltip>
                </Grid>

                <Grid>
                    {val.technicians && (
                        // <Tooltip title={val.technicians} aria-label="add" disableFocusListener>
                        <IconButton title={`${val.technicians}`} size="small" onClick={() => state.currentWorkflowId == 8 && handleAssignmentBtnClick(val)}>
                            <AssignmentIndIcon
                                style={{
                                    color: val.returnReasonTextColourCode
                                }}
                                fontSize="small"
                            />
                        </IconButton>
                        // </Tooltip>
                    )}
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const baseColumns = [
        {
            title: 'Action',
            field: 'act',
            renderer: editButton,
            width: 95,
            filterable: false
        },
        { field: 'wipNumber', sortable: true, title: `${portalSettings.portalSettingGridWIPNoLabel || 'WIP No'}`, width: 70 },
        { field: 'registration', sortable: true, title: `${portalSettings.portalSettingGridVehicleRegLabel || 'Vehicle Reg'}`, width: 100 },
        { field: 'makeModel', sortable: true, title: `${portalSettings.portalSettingGridModelLabel || 'Model'}`, width: 200 },
        { field: 'customerName', sortable: true, title: `${portalSettings.portalSettingGridCustomerNameLabel || 'Customer Name'}`, width: 170 },
        {
            field: 'dateDueIn',
            sortable: true,
            title: `${portalSettings.portalSettingGridDateDueInLabel || 'Due Date In'}`,
            // type: 'date',
            type: 'dateTime',
            // flex: 0.6
            width: 135,
            renderer: function (value, record) {
                if (record.dateDueIn != null) {
                    return moment(record.dateDueIn).format('DD-MMM-YYYY HH:mm');
                }
                return '-';
            }
        },
        {
            field: 'dateDueOut',
            sortable: true,
            title: `${portalSettings.portalSettingGridDateDueOutLabel || 'Date Due Out'}`,
            // type: 'date',
            type: 'dateTime',
            width: 135,
            renderer: function (value, record) {
                if (record.dateDueOut != null) {
                    return moment(record.dateDueOut).format('DD-MMM-YYYY HH:mm');
                }
                return '-';
            }
        },
        { field: 'returnReason', sortable: true, title: `${portalSettings.portalSettingGridReturnReasonLabel || 'Return Reason'}`, width: 190 },
        // { field: 'chassisNumber', sortable: true,title: 'Chassis Number',  },
        { field: 'branchShortName', sortable: true, title: `${portalSettings.portalSettingGridBranchLabel || 'Branch'}`, width: 120 },
        { field: 'franchiseName', sortable: true, title: `${portalSettings.portalSettingGridFranchiseLabel || 'Franchise'}`, width: 160 },
        { field: 'st', sortable: true, title: `${portalSettings.portalSettingGridJobDescriptionLabel || 'Job Description'}`, width: 170 },
        { field: 'accountNo', sortable: true, title: `${portalSettings.portalSettingGridAccountLabel || 'Account'}`, width: 80 },
        { field: 'cb', sortable: true, title: `${portalSettings.portalSettingGridCreatedByLabel || 'Created By'}`, width: 100 },
        {
            field: 'assigned',
            sortable: true,
            title: `${portalSettings.portalSettingGridAssignedDateLabel || 'Assigned Date'}`,
            width: 120,
            renderer: function (value, record) {
                if (record.assigned != null) {
                    return moment(record.assigned).format('DD-MMM-YYYY HH:mm');
                }
                return '-';
            }
        },
        {
            field: 'taken',
            sortable: true,
            title: `${portalSettings.portalSettingGridTimeTakenLabel || 'Time Taken'}`,
            width: 100
        },
        {
            field: 'status',
            sortable: true,
            title: `${portalSettings.portalSettingGridStatusLabel || 'Status'}`,
            width: 100
        },
        {
            field: 'assignedDays',
            sortable: true,
            title: `${portalSettings.portalSettingGridAssignedDaysLabel || 'Assigned Days'}`,
            width: 110
        },
        {
            field: 'owningOperator',
            sortable: true,
            title: `${portalSettings.portalSettingGridOwningOperatorLabel || 'Owning Operator'}`,
            width: 130
        },
        {
            field: 'jobAssignedUser',
            sortable: true,
            title: `${portalSettings.portalSettingGridJobAssignedUserLabel || 'Assigned User'}`,
            width: 110
        }
    ];

    if (state.currentWorkflowId === 'All') {
        baseColumns.push({
            field: 'wn',
            sortable: true,
            isShow: true,
            title: `Workflow Name`,
            width: 160
        });
    }

    const regNumberRendrer = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid
                onClick={() => setState((st) => ({ ...st, showMotHistory: true, regNo: record?.registration, FranchiseCode: record?.f }))}
                style={{ cursor: 'pointer' }}
            >
                {value}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    let Column = baseColumns
        .map((c) => {
            let obj = {};
            if (Object.keys(state.tempObj).length > 0 && !c.isShow) {
                obj.sequenceNo = state.tempObj[config[c.field]?.getSequence] || 9999999;
                if (c.title !== 'Action') {
                    obj.hidden = !state.tempObj[config[c.field]?.from];
                }
            } else {
                obj.hidden = false;
            }
            if (c.title !== 'Action' && c.field !== 'registration' && c.field !== 'chassisNumber') {
                obj.events = {
                    click: function (e) {
                        props.history.push(`/Job/${e.data.record.headerRecordID}/${e.data.record.workflowID}`);
                    }
                };
            }
            if (c.field == 'registration') {
                c.renderer = regNumberRendrer({});
            }
            if (c.title == 'Action') {
                obj.sequenceNo = -1;
            }
            return {
                ...c,
                ...obj,
                headerCssClass: 'gridHeader'
            };
        })
        .sort((a, b) => {
            return a.sequenceNo - b.sequenceNo;
        });

    const buttonClicked = async (btn) => {
        localStorage.setItem('currentWorkflowId', btn.id);
        setState((st) => {
            const newSt = { ...st };
            if (!newSt.hasQueryValues) {
                newSt.returnReasonID = '';
            } else {
                newSt.hasQueryValues = false;
            }
            newSt.currentWorkflowId = `${btn.id}`;
            newSt.currentWorkflowLabel = btn.label;
            newSt.displayJobPriorityButton = btn.displayJobPriorityButton || false;
            newSt.displayCustomerRatingButton = btn.displayCustomerRatingButton || false;
            return newSt;
        });
    };

    const handleOpen = () => {
        let tempArr = [...Column]
            .map((q) => {
                return {
                    field: q.field,
                    title: q.title,
                    hidden: !q.hidden,
                    fieldName: config[q.field]?.to,
                    isShow: q.isShow ? q.isShow : false,
                    postSequenceFieldName: config[q.field]?.postSequence
                };
            })
            .filter((p) => (!p.isShow && p.title != 'Action' ? p : null));
        // tempArr.push({
        //     field: 'pagination',
        //     title: 'Pagination',
        //     hidden: state.displayPagination,
        //     fieldName: 'UserGridColumnDisplayPagination'
        // });

        setState((st) => ({ ...st, showFilter: true, filterList: tempArr }));
    };

    const closeDialog = (res) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.showFilter = false;
            newSt.showTechAssign = false;
            if (res) newSt.isReload = new Date();
            return newSt;
        });
        res && pullGridFilterList();
        res && pullWorkFlowsButtons();
    };

    const closeTechAssignDialog = (res) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.showJobPriority = false;
            newSt.showTechAssign = false;
            newSt.showCustomerRating = false;
            newSt.displayCustomerMessage = false;
            if (res) newSt.isReload = new Date();
            return newSt;
        });
    };

    // let getUrl = `WorkflowJobs/GetWorkflow/${state.currentWorkflowId === 'All' ? '' : state.currentWorkflowId}?${
    //     state.returnReasonID ? `ReturnReasonID=${state.returnReasonID}` : ''
    // }${state.timeID ? `&Time=${state.timeID}` : ''}`;
    useEffect(async () => {
        if (portalSettings.portalSettingGridRefreshTime > 0) {
            const intervalId = await setInterval(() => {
                setState((st) => {
                    const newSt = { ...st };
                    newSt.isReload = new Date();
                    return newSt;
                });
            }, portalSettings.portalSettingGridRefreshTime * 1000);
            return () => clearInterval(intervalId);
        }
    }, [portalSettings.portalSettingGridRefreshTime]);

    useEffect(() => {
        let tempUrl = '';
        if (state.fromReports == 'No') {
            tempUrl = `WorkflowJobs/GetWorkflow/${state.currentWorkflowId === 'All' ? '' : state.currentWorkflowId}?${
                state.returnReasonID ? `ReturnReasonID=${state.returnReasonID}` : ''
            }${state.timeID ? `&Time=${state.timeID}` : ''}${state.dueTime ? `&dueTime=${state.dueTime}` : ''}`;
        } else {
            console.log(state.groupIds.length, 'state.groupIds.length');
            let BranchID = state.groupIds.length > 0 ? `BranchID=${state.groupIds.join('&BranchID=')}` : '';
            tempUrl = `DashBoard/GetWorkflowAvgTimeList/${state.currentWorkflowId === 'All' ? '' : state.currentWorkflowId}?startDate=${
                state.startDate
            }&endDate=${state.endDate}&${BranchID}`;
        }
        setState((st) => {
            const newSt = { ...st };
            newSt.getUrl = tempUrl;
            newSt.isReload = new Date();
            return newSt;
        });
    }, [state.currentWorkflowId, state.returnReasonID, state.timeID, state.dueTime, state.fromReports, state.startDate, state.endDate, state.groupIds]);

    useEffect(() => {
        setState((st) => {
            const newSt = { ...st };
            newSt.isGridColumnReload = new Date();
            return newSt;
        });
    }, [state.displayJobPriorityButton]);

    const modalSubHeaderTitle = () => {
        return (
            <div>
                <span style={{ fontSize: '15px' }}>
                    WIP No: <b>{state.wip}</b> Reg No: <b>{state.reg}</b>
                </span>
            </div>
        );
    };
    const handleChanges = (event, value) => {
        setState((st) => {
            const nst = { ...st, groupIds: value.map((c) => c.id) };
            return nst;
        });
    };

    const handleinput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    return (
        <div className="filter-Container">
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <BasicButtonGroup
                    margin={6}
                    buttons={state.workflowButtons}
                    onClick={buttonClicked}
                    btnSelectedId={state.btnSelectedId || null}
                    className="left-align-buttons"
                    allGrid={true}
                />
                <span
                //  className="filter-div"
                >
                    <IconButton aria-label="filter" onClick={handleOpen} style={{ padding: '0px' }}>
                        <FilterListIcon />
                    </IconButton>
                </span>
            </div>
            {state.fromReports != 'No' && (
                <Grid container spacing={1} style={{ marginLeft: '2px', marginTop: 3 }}>
                    <Grid item xs={6} md={4} lg={2}>
                        <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 10 }}>
                            Start Date
                        </FormLabel>
                        <DatePicker
                            placeholder="Start Date"
                            InputProps={{ inputProps: { max: `${state.endDate}` } }}
                            name="startDate"
                            fullWidth
                            value={state.startDate}
                            onChange={handleinput}
                        />
                    </Grid>
                    <Grid item xs={6} md={4} lg={2}>
                        <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 10 }}>
                            End Date
                        </FormLabel>
                        <DatePicker
                            placeholder="End Date"
                            InputProps={{ inputProps: { min: `${state.startDate}` } }}
                            name="endDate"
                            fullWidth
                            value={state.endDate}
                            onChange={handleinput}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 2 }}>
                            Branch
                        </FormLabel>
                        <MultipleSelectWithMoreOp options={state.branchList || []} onChange={handleChanges} value={temp.groupVal} />
                    </Grid>
                </Grid>
            )}
            <div>
                {state.currentWorkflowId && !_.isEmpty(state.tempObj) && (
                    <GijgoGrid
                        columns={Column}
                        getUrl={state.getUrl}
                        currentWorkflowLabel={state.currentWorkflowLabel}
                        currentWorkflowId={state.currentWorkflowId}
                        filterList={state.tempObj}
                        displayPagination={state.displayPagination}
                        isReload={state.isReload}
                        isGridColumnReload={state.isGridColumnReload}
                    />
                )}
            </div>
            {state.showFilter ? (
                <DialogComp title="My Preferences" maxWidth="md" onClose={() => closeDialog(false)} overflow="auto">
                    <FilterModalMainTabComponent
                        languageCode={state.languageCode}
                        appbarLocation={state.appbarLocation}
                        filterConfig={config}
                        filterList={state.filterList}
                        handleCancel={closeDialog}
                        languageData={state.languageData}
                        isPagination={state.displayPagination}
                    />
                    {/* <GridFilterModal
                        UserPreferenceWorkflowsList={state.UserPreferenceWorkflowsList}
                        languageCode={state.languageCode}
                        appbarLocation={state.appbarLocation}
                        filterConfig={config}
                        filterList={state.filterList}
                        handleCancel={closeDialog}
                        languageData={state.languageData}
                    /> */}
                </DialogComp>
            ) : null}
            {state.showTechAssign ? (
                <DialogComp title="Assigned Technician" maxWidth="sm" onClose={() => closeTechAssignDialog(false)} fullWidth>
                    <AssignTech jobId={state.jobId} handleCancel={closeTechAssignDialog} />
                </DialogComp>
            ) : null}
            {state.showJobPriority ? (
                <DialogComp title="Job Priority" maxWidth="xs" onClose={() => closeTechAssignDialog(false)} fullWidth>
                    <JobPriorityModal jobId={state.jobId} workFlowData={state.workFlowData} handleCancel={closeTechAssignDialog} />
                </DialogComp>
            ) : null}
            {state.showCustomerRating ? (
                <DialogComp title="Customer Rating" maxWidth="sm" onClose={() => closeTechAssignDialog(false)} fullWidth>
                    <CustomerRating jobId={state.jobId} workFlowData={state.workFlowData} handleCancel={closeTechAssignDialog} />
                </DialogComp>
            ) : null}
            {state.jobNotes ? (
                <JobNotesModal
                    modalSubHeaderTitle={modalSubHeaderTitle}
                    maxWidth="md"
                    jobId={state.headerRecordID}
                    onClose={() => handleJobNotes([], false)}
                    onNotesAdded={() => {
                        console.log('');
                    }}
                />
            ) : null}
            {state.displayCustomerMessage ? (
                <DialogComp title="Customer Rating Message" maxWidth="xs" onClose={() => closeTechAssignDialog(false)} fullWidth>
                    <Typography variant="h6" style={{ textAlign: 'center' }} color="secondary">
                        {state.customerMessage}
                    </Typography>
                </DialogComp>
            ) : null}
            {state.logHistory ? (
                <LogHistory
                    headerRecordID={state.headerRecordID}
                    reg={state.reg}
                    wip={state.wip}
                    onClose={() => {
                        setState((st) => ({
                            ...st,
                            logHistory: false
                        }));
                    }}
                />
            ) : null}
            {state.showMotHistory && (
                <DialogComp
                    title="MOT History"
                    onClose={() => setState((st) => ({ ...st, showMotHistory: false }))}
                    maxWidth="md"
                    fullWidth
                    overflow={'auto'}
                    height="680px"
                >
                    <MotHistoryScreen regNo={state.regNo} FranchiseCode={state.FranchiseCode} hideSeachText={true} />
                </DialogComp>
            )}
        </div>
    );
};
export default Dashboard;
