import React from 'react';
import moment from 'moment';
import { Grid, FormLabel, Breadcrumbs, Unstable_TrapFocus, InputLabel } from '@material-ui/core';
import { TextBox } from '../../../../Core/FormInput';
import NestedGijgoGrid from '../../../../Core/Controls/GijgoGrid/nestedGijgoGrid';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import { useState } from 'react';

var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
let addCommas = (val) => {
    return val ? `${val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '0.00';
};

let startDate = moment().startOf('month').format('YYYY-MM-DD');
let endDate = moment().format('YYYY-MM-DD');

const baseColumns = [
    { title: 'Sold By', field: 'sp', width: 100, sortable: true },
    { title: 'VSB', field: 'vsb', width: 70, sortable: true },
    { title: 'Customer', field: 'cust', width: 100, sortable: true },
    { title: 'Branch', field: 'loc', width: 90, sortable: true },
    {
        title: 'Reg Number',
        field: 'reg',
        width: 80,
        sortable: true
    },
    { title: 'Model', field: 'model', width: 90, sortable: true },
    {
        title: 'Invoice Date',
        field: 'id',
        width: 90,
        sortable: true,
        type: 'date',
        format: 'dd-mmm-yyyy',
        cssClass: 'text-center',
        renderer: function (value, record) {
            if (record.id != null) {
                let date = new Date(record.id);
                return date.getDate() + '-' + monthNames[date.getMonth()] + '-' + date.getFullYear();
            }
            return '';
        }
    },
    // {
    //     title: 'Sale Price',
    //     field: 'sale',
    //     width: 90,
    //     sortable: true,
    //     renderer: function (value, record) {
    //         return '<span style="cursor:pointer" title="' + record.sale + '"  >£' + addCommas(record.sale) + '</span>';
    //     },
    //     align: 'right'
    // },
    // {
    //     title: 'Cost Price',
    //     field: 'cost',
    //     width: 70,
    //     sortable: true,
    //     renderer: function (value, record) {
    //         return '<span style="cursor:pointer" title="' + record.cost + '"  >£' + addCommas(record.cost) + '</span>';
    //     },
    //     align: 'right'
    // },
    // {
    //     title: 'Disc O/A',
    //     field: 'DiscOval',
    //     width: 70,
    //     sortable: true,
    //     renderer: function (value, record) {
    //         return '<span style="cursor:pointer" title="' + record.DiscOval + '"  >£' + addCommas(record.DiscOval) + '</span>';
    //     },
    //     align: 'right'
    // },
    // {
    //     title: 'Bonuses',
    //     field: 'Bonuses',
    //     width: 70,
    //     sortable: true,
    //     renderer: function (value, record) {
    //         return '<span style="cursor:pointer" title="' + record.Bonuses + '"  >£' + addCommas(record.Bonuses) + '</span>';
    //     },
    //     align: 'right'
    // },
    // {
    //     title: 'Subsidy',
    //     field: 'Subsidy',
    //     width: 70,
    //     sortable: true,
    //     renderer: function (value, record) {
    //         return '<span style="cursor:pointer" title="' + record.Subsidy + '"  >£' + addCommas(record.Subsidy) + '</span>';
    //     },
    //     align: 'right'
    // },
    // {
    //     title: 'CPI',
    //     field: 'CPI',
    //     width: 70,
    //     sortable: true,
    //     renderer: function (value, record) {
    //         return '<span style="cursor:pointer" title="' + record.CPI + '"  >£' + addCommas(record.CPI) + '</span>';
    //     },
    //     align: 'right'
    // },
    // {
    //     title: 'Finance',
    //     field: 'Finance',
    //     width: 70,
    //     sortable: true,
    //     renderer: function (value, record) {
    //         return '<span style="cursor:pointer" title="' + record.Finance + '"  >£' + addCommas(record.Finance) + '</span>';
    //     },
    //     align: 'right'
    // },
    // {
    //     title: 'PDI',
    //     field: 'pdi',
    //     width: 70,
    //     sortable: true,
    //     renderer: function (value, record) {
    //         return '<span style="cursor:pointer" title="' + record.pdi + '"  >£' + addCommas(record.pdi) + '</span>';
    //     },
    //     align: 'right'
    // },

    {
        title: 'Total Sale',
        field: 'ts',
        width: 90,
        sortable: true,
        renderer: function (value, record) {
            return '<span style="cursor:pointer" title="' + record.ts + '"  >£' + addCommas(record.ts) + '</span>';
        },
        align: 'right'
    },
    {
        title: 'Total Cost',
        field: 'tc',
        width: 70,
        sortable: true,
        renderer: function (value, record) {
            return '<span style="cursor:pointer" title="' + record.tc + '"  >£' + addCommas(record.tc) + '</span>';
        },
        align: 'right'
    },
    {
        title: 'Gross Profit',
        field: 'gp',
        width: 70,
        sortable: true,
        renderer: function (value, record) {
            return '<span style="cursor:pointer" title="' + record.gpp + '"  >£' + addCommas(record.gp) + '</span>';
        },
        align: 'right'
    }
];

const detailTemplate = () => {
    return `<div style='width:100%'>
        <table style='width:100%;'>
            <tr style='background-color:#DCDCDC;' >
                
                <th style='font-weight:750; text-align:left;'>Order Number</th>
                <th style='font-weight:750; text-align:left;'>Sale Price</th>
                <th style='font-weight:750; text-align:left;'>Cost Price</th>
                <th style='font-weight:750; text-align:left;'>Disc O/A</th>
                <th style='font-weight:750; text-align:left;'>Bonuses</th>
                <th style='font-weight:750; text-align:left;'>Subsidy</th>
                <th style='font-weight:750; text-align:left;'>CPI</th>
                <th style='font-weight:750; text-align:left;'>Finance</th>
                <th style='font-weight:750; text-align:left;'>PDI</th>
                <th style=' font-weight:750; text-align:left;'>DFA Sale</th>
                <th style='text-align:left;font-weight:750;'>DFA Cost</th>
                <th style='text-align:left;font-weight:750;'>Intro Comm</th>
                <th style='width:20%; font-weight:750; text-align:left;'>Finance Company</th>
                <th style='font-weight:750; text-align:left;'>Shipment Number</th>
            </tr>
            <tr>
                
                <td>{ord}</td>
                <td>£{sale}</td>
                <td>£{cost}</td>
                <td>£{discOval}</td>
                <td>£{bonuses}</td>
                <td>£{subsidy}</td>
                <td>£{cpi}</td>
                <td>£{finance}</td>
                <td>£{pdi}</td>
                <td>£{dfaSale}</td>
                <td>£{dfaCost}</td>
                <td>£{introCom}</td>
                <td>{fcompany}</td>
                <td>{ship}</td>
            </tr>
        </table>
        </div>`;
};

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Sales', active: true },
    { name: 'New Vehicle Profit Report', path: '/', active: true }
];

const NewVehicleProfitReport = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let startDateParams = params.get('start') || '';
    let endDateParams = params.get('end') || '';

    const [state, setState] = useState({
        startDate: startDateParams || startDate,
        endDate: endDateParams || endDate
    });

    const handleinput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    const baseUrl = `Sales/NewVehicleProfitReport?StartDate=${state.startDate}&EndDate=${state.endDate}`;
    return (
        <div>
            <BreadCrumbs crumbs={crumbs} />
            <Grid container style={{ padding: '0px 10px' }}>
                <Grid item container spacing={2} xs={12}>
                    <Grid item xs={6} sm={6} md={3}>
                        <InputLabel className="mandatoryfields" shrink>
                            Start Date
                        </InputLabel>

                        <TextBox
                            placeholder="Start Date"
                            type="date"
                            name="startDate"
                            fullWidth
                            value={state.startDate}
                            onChange={handleinput}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { max: `${state.endDate}` } }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <InputLabel className="mandatoryfields" shrink>
                            End Date
                        </InputLabel>

                        <TextBox
                            placeholder="End Date"
                            name="endDate"
                            fullWidth
                            type="date"
                            value={state.endDate}
                            onChange={handleinput}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{ inputProps: { min: `${state.stateDate}` } }}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <NestedGijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list.map((n) => ({
                                ...n,
                                sale: n.sale || 0,
                                cost: n.cost || 0,
                                discOval: n.discOval || 0,
                                bonuses: n.bonuses || 0,
                                subsidy: n.subsidy || 0,
                                cpi: n.cpi || 0,
                                finance: n.finance || 0,
                                pdi: n.pdi || 0
                            })),
                            total: res?.data?.total
                        })}
                        columns={baseColumns}
                        getUrl={baseUrl}
                        displayPagination={true}
                        detailTemplate={detailTemplate}
                        downloadName={'New Vehicle Profit Report'}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default NewVehicleProfitReport;
