import React, { useEffect, useLayoutEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Button, ButtonGroup, Card, CircularProgress, Grid, Hidden, Typography } from '@material-ui/core';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, ComposedChart } from 'recharts';
import { PieChart, Pie, Sector, LabelList } from 'recharts';
import { Wifi } from '@material-ui/icons';
import './mainDashboard.scss';
import { useHistory } from 'react-router-dom';
import { SecondaryButton } from '../FormInput';
import { useWindowSize } from '../Controls/ScreenResolution';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        margin: 3,
        border: '2px solid white ',
        Opacity: 0.9,
        minWidth: 120,
        padding: '5px 0px',
        background: 'white',
        borderRadius: 10
    }
}));

let setObject = {
    ['<24hr']: '#00F433',
    ['1-5 days']: '#88EBA4',
    ['5-10 days']: '#FFBF00',
    ['10-20 days']: '#FFE28A',
    ['21-30 days']: '#bc4443',
    ['>30 days']: '#F11C0E'
};

let timenumber = {
    ['<24hr']: 1,
    ['1-5 days']: 2,
    ['5-10 days']: 3,
    ['10-20 days']: 4,
    ['21-30 days']: 5,
    ['>30 days']: 6
};

const COLORS = [
    '#0074D9',
    '#FF4136',
    '#2ECC40',
    '#FF851B',
    '#7FDBFF',
    '#B10DC9',
    '#FFDC00',
    '#001f3f',
    '#39CCCC',
    '#01FF70',
    '#85144b',
    '#F012BE',
    '#3D9970',
    '#111111',
    '#AAAAAA'
];

function randomColor() {
    let hex = Math.floor(Math.random() * 0xffffff);
    let color = '#' + hex.toString(16);

    return color;
}

const DashboardCharts = (props) => {
    const { state } = props;
    const classes = useStyles();
    const history = useHistory();
    const [array, setArray] = useState({
        workflowReturnReasonArray: [],
        keys: [],
        byTimeObj: {},
        selectRRarr: [],
        showLoader: true,
        selectedFilterTechData: [],
        techJobsTotal: 0,
        hourType: 'remaining',
        assignedDate: 2
    });

    const [width, height] = useWindowSize();

    useEffect(() => {
        let btTimeData = JSON.parse(localStorage.getItem('FiltterParams'));

        if (btTimeData?.branchShortName) {
            props.getbranchByOnchange(btTimeData?.branchShortName);
        } else {
            props.getbranchByOnchange('Default');
        }
    }, []);

    useEffect(() => {
        if (state.sumOFByTimeForALL) {
            let btTimeData = JSON.parse(localStorage.getItem('FiltterParams'));
            if (state.sumOFByTimeForALL) {
                findByTimeValues(btTimeData?.branchShortName || 'Default');
            }
        }
    }, [state.sumOFByTimeForALL]);

    useEffect(() => {
        let tempData = JSON.parse(localStorage.getItem('_dashboard_')) || {};
        let btTimeData = JSON.parse(localStorage.getItem('FiltterParams'));
        localStorage.setItem('FiltterParams', JSON.stringify({ ...btTimeData, branchTime: '' }));

        if (state) {
            if (state.workFlowDataBarData) {
                pullWorkflowRRdata(tempData.workflowReturnReason || state.workFlowDataBarData[0]?.workflow);
            }

            if (state.chartdata) {
                setArray((st) => ({
                    ...st,
                    showLoader: false,
                    timeByBranch: tempData?.BranchBytime || state?.chartdata[0]?.name
                }));
            }
            if (!tempData) {
                localStorage.setItem(
                    '_dashboard_',
                    JSON.stringify({
                        ...tempData,
                        workflowReturnReason: state.workFlowDataBarData[0]?.workflow
                    })
                );
            }
        }
        if (btTimeData) {
            setArray((st) => ({ ...st, byTimeBranch: btTimeData.branchShortName }));
        }
    }, [state.workFlowDataBarData, state.chartdata]);

    useEffect(() => {
        let tempData = JSON.parse(localStorage.getItem('_dashboard_')) || {};
        if (state.technicianJobsData) {
            pullTechnicianJobsData(tempData?.selectedValueByTech || state?.chartdata[0]?.name);
        }
    }, [state.technicianJobsData, state.chartdata]);

    const pullTechnicianJobsData = (val) => {
        let tempLocalData = JSON.parse(localStorage.getItem('_dashboard_'));
        let tempData = [...state.technicianJobsData].find((m) => m.bn == val) || [];
        let techJobsTotal = 0;
        if (tempData?.length > 0) {
            tempData.forEach((q) => {
                techJobsTotal += q.total;
            });
        }
        setArray((st) => ({
            ...st,
            selectedValueByTech: val,
            selectedFilterTechData: tempData,
            techJobsTotal: techJobsTotal
        }));
        localStorage.setItem('_dashboard_', JSON.stringify({ ...tempLocalData, selectedValueByTech: val }));
    };

    const pullWorkflowRRdata = (value) => {
        let newArray = [];
        let keys = [];
        if (state.workFlowDataBarData) {
            let index = state.workFlowDataBarData.find((n) => n.workflow === value);
            if (index) {
                keys = Object.keys(index);
                newArray.push(index);
            }
            setArray((st) => ({
                ...st,
                workflowReturnReasonArray: newArray,
                keys: keys,
                workFlowRR: value
            }));
        }
    };

    let handleArray = (e) => {
        let workflowValue = e.target.value;
        let btTimeData = JSON.parse(localStorage.getItem('FiltterParams'));
        let tempData = JSON.parse(localStorage.getItem('_dashboard_'));
        localStorage.setItem('_dashboard_', JSON.stringify({ ...tempData, workflowReturnReason: workflowValue }));
        pullWorkflowRRdata(workflowValue);
        props.getbranchByOnchange(btTimeData?.branchShortName || 'Default', workflowValue);
    };

    let findByTimeValues = (val) => {
        let indexObj = {};

        if (val === 'Default') {
            if (state.sumOFByTimeForALL) {
                let letNewData = state?.sumOFByTimeForALL?.reduce((acc, obj) => {
                    var index = acc.find((item) => item);
                    if (index) {
                        index.lessThan24HoursOld += obj.lessThan24HoursOld;
                        index.between1And5DaysOld += obj.between1And5DaysOld;
                        index.between5And10DaysOld += obj.between5And10DaysOld;
                        index.between10And20DaysOld += obj.between10And20DaysOld;
                        index.between21And30DaysOld += obj.between21And30DaysOld;
                        index.over30DaysOld += obj.over30DaysOld;
                        index.preBooking += obj.preBooking;
                        index.between1to10Days += obj.between1to10Days;
                        index.between11And20Days += obj.between11And20Days;
                        index.between21And30Days += obj.between21And30Days;
                        index.overdueOver30DaysOld += obj.overdueOver30DaysOld;
                        index.overdueArchived += obj.overdueArchived;
                        // index.total += obj.total;
                        return acc;
                    }
                    acc.push({ ...obj });
                    return acc;
                }, []);
                indexObj = letNewData.find((item) => item);
                indexObj.bn = '';
                setArray((st) => ({ ...st, byTimeObj: indexObj, byTimeBranch: val }));
            }
        } else {
            indexObj = state.sumOFByTimeForALL.find((n) => n.bn === val);
            setArray((st) => ({ ...st, byTimeObj: indexObj, byTimeBranch: val }));
        }
    };

    const handleChangeByTime = (e) => {
        let tempData = JSON.parse(localStorage.getItem('_dashboard_'));
        let valueByTime = e.target.value;
        props.getbranchByOnchange(valueByTime);
        findByTimeValues(valueByTime);
        localStorage.setItem(
            'FiltterParams',
            JSON.stringify({
                branchShortName: valueByTime !== 'Default' ? valueByTime : ''
            })
        );
        if (valueByTime !== 'Default') {
            localStorage.setItem(
                '_dashboard_',
                JSON.stringify({
                    ...tempData,
                    BranchBytime: valueByTime,
                    selectedValueByTech: valueByTime
                })
            );
        }
    };

    const handleTimeBranch = (e) => {
        let tempData = JSON.parse(localStorage.getItem('_dashboard_'));
        let valueByTimeBranch = e.target.value;
        setArray((st) => ({ ...st, timeByBranch: valueByTimeBranch }));

        localStorage.setItem('_dashboard_', JSON.stringify({ ...tempData, BranchBytime: valueByTimeBranch }));
    };
    const handleTechSelect = (e) => {
        let valueByTech = e.target.value;
        pullTechnicianJobsData(valueByTech);
    };

    const handleTechOnClick = (techObj) => {
        localStorage.setItem('FiltterParams', JSON.stringify({ branchShortName: techObj.bn }));
        let searchQuery = `&tid=${techObj.tid || ''}&bnm=${techObj.bn}&tnm=${techObj.name}&bid=${techObj.bid}`;
        history.push({
            pathname: '/techDashgrid',
            search: searchQuery,
            state: {}
        });
    };
    const handleBar = (val) => {
        if (val._reactName === 'onChange') {
            setArray((st) => ({ ...st, hourType: val.target.value }));
        } else {
            setArray((st) => ({ ...st, hourType: val }));
        }
    };
    const handleDate = (val) => {
        if (val._reactName === 'onChange') {
            setArray((st) => ({ ...st, assignedDate: val.target.value }));
        } else {
            setArray((st) => ({ ...st, assignedDate: val }));
        }
    };

    const DateObj = [
        {
            assignedDateObj: [
                { time: 1, label: 'Less Than 24Hr', BarColor: '5px solid #00F433', value: array.byTimeObj?.lessThan24HoursOld },
                { time: 2, label: '1 to 5 Days', BarColor: '5px solid #88EBA4', value: array.byTimeObj?.between1And5DaysOld },
                { time: 3, label: '5 to 10 Days', BarColor: '5px solid #FFBF00', value: array.byTimeObj?.between5And10DaysOld },
                { time: 4, label: '10 to 20 Days', BarColor: '5px solid #FFE28A', value: array.byTimeObj?.between10And20DaysOld },
                { time: 5, label: '20 to 30 Days', BarColor: '5px solid #bc4443', value: array.byTimeObj?.between21And30DaysOld },
                { time: 6, label: 'Over 30 Days', BarColor: '5px solid #F11C0E', value: array.byTimeObj?.over30DaysOld }
            ],
            OverDateObj: [
                { dueTime: 1, label: 'Pre Booking', BarColor: '5px solid #88EBA4', value: array.byTimeObj?.preBooking },
                { dueTime: 2, label: '1 to 10 Days', BarColor: '5px solid #FFBF00', value: array.byTimeObj?.between1to10Days },
                { dueTime: 3, label: '10 to 20 Days', BarColor: '5px solid #FFE28A', value: array.byTimeObj?.between11And20Days },
                { dueTime: 4, label: '20 to 30 Days', BarColor: '5px solid #bc4443', value: array.byTimeObj?.between21And30Days },
                { dueTime: 5, label: 'Over 30 Days', BarColor: '5px solid #F11C0E', value: array.byTimeObj?.overdueOver30DaysOld },
                { dueTime: -1, label: 'Archived', BarColor: '5px solid #F11C0E', value: array.byTimeObj?.overdueArchived }
            ]
        }
    ];

    const WipsDate = [
        { id: 2, name: 'Overdue WIPs' },
        { id: 1, name: 'Assigned Date' }
    ];

    const HoursData = [
        { id: 'remaining', name: 'Remaining Hours' },
        { id: 'clocked', name: 'Clocked Hours' },
        { id: 'allowed', name: 'Allowed Hours' }
    ];

    let getDateObj = array.assignedDate == 1 ? DateObj[0].assignedDateObj : DateObj[0].OverDateObj;
    let getHoursObj = array.hourType === 'remaining' ? state.hoursRemaining : state.clockedHours;
    return (
        <Grid item container alignContent="flex-start" style={{ overflow: 'auto', height: 'calc(100vh - 215px)' }} className="custom-scroll">
            {/* *****************Branch************** */}

            <Grid item container xs={12} sm={12} md={12} lg={4} xl={3} style={{ padding: 5 }}>
                {state.showHeaderLoader ? (
                    <div className={classes.root}>
                        <div className="pieChartSize" style={{ display: 'flex' }}>
                            <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto', alignSelf: 'center' }} />
                        </div>
                    </div>
                ) : (
                    <Card className={classes.root} style={{ position: 'relative' }}>
                        <Typography varient="h5" color="secondary" className="dashHeading">
                            By Branch
                        </Typography>
                        <div className="pieChartSize">
                            <ResponsiveContainer>
                                <PieChart>
                                    <Pie
                                        data={state.chartdata}
                                        cx={70}
                                        cy={60}
                                        paddingAngle={3}
                                        outerRadius={60}
                                        innerRadius={40}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {state.chartdata.map((entry, index) => (
                                            <>
                                                <Cell
                                                    key={`cell-${index}`}
                                                    fill={COLORS[index % COLORS.length]}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => props.onChartClickHandler({ workflowID: 'All', bn: entry.name }, true)}
                                                />
                                            </>
                                        ))}
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                right: 30,
                                top: 20,
                                maxHeight: '130px',
                                overflowY: 'auto',
                                overflowX: 'hidden'
                            }}
                            className="custom-scroll"
                        >
                            {state?.chartdata.map((m, i) => {
                                let color = COLORS[i];
                                return (
                                    <div style={{ padding: 0 }}>
                                        <div
                                            style={{
                                                fontSize: 13,
                                                maxWidth: 210,
                                                minWidth: 110,
                                                display: 'flex',
                                                justifyContent: 'flex-start'
                                            }}
                                        >
                                            {' '}
                                            <div
                                                style={{
                                                    background: color,
                                                    height: 10,
                                                    width: 10,
                                                    marginTop: 4
                                                }}
                                            ></div>
                                            <div style={{ fontWeight: 700 }}>&nbsp;{m.value}</div>
                                            <div>&nbsp;{m.name}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </Card>
                )}
            </Grid>

            {/* *****************Date************** */}
            <Grid item container xs={12} sm={12} md={12} lg={8} xl={9} style={{ padding: 5, boxSize: 'none' }}>
                {state.showHeaderLoader ? (
                    <div className={classes.root}>
                        <div className="pieChartSize" style={{ display: 'flex' }}>
                            <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto', alignSelf: 'center' }} />
                        </div>
                    </div>
                ) : (
                    <Card className={classes.root}>
                        <Hidden only={['xs']}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={8}>
                                    <div
                                        style={{
                                            margin: '10px 10px',
                                            textAlign: 'left'
                                        }}
                                    >
                                        <ButtonGroup color="secondary" ria-label="outlined primary button group" size="small">
                                            {WipsDate.map((n) => {
                                                return (
                                                    <Button
                                                        variant={array.assignedDate === n.id ? 'contained' : 'outlined'}
                                                        onClick={() => handleDate(n.id)}
                                                        style={{ fontSize: 10 }}
                                                    >
                                                        {n.name}
                                                    </Button>
                                                );
                                            })}
                                        </ButtonGroup>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div
                                        style={{
                                            float: 'right',
                                            margin: 10
                                        }}
                                    >
                                        <select onChange={handleChangeByTime} className="selectclass" value={array.byTimeBranch}>
                                            <option value={'Default'}>All Branches</option>
                                            {state.chartdata.map((btn, i) => {
                                                return (
                                                    <option key={i} value={btn.name}>
                                                        {btn.name}{' '}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </Grid>
                            </Grid>
                        </Hidden>
                        <Hidden only={['sm', 'xl']}>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <div
                                        style={{
                                            margin: '0px 20px',
                                            textAlign: 'left'
                                        }}
                                    >
                                        <span className="lableFont">Dates</span>
                                        <select
                                            color="secondary"
                                            onChange={handleDate}
                                            aria-label="outlined primary button group"
                                            size="small"
                                            className="ButtonDropDown"
                                            value={array.assignedDate}
                                        >
                                            {WipsDate.map((n, i) => {
                                                return (
                                                    <option key={i} value={n.id}>
                                                        {n.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div
                                        style={{
                                            margin: '0px 20px',
                                            textAlign: 'left'
                                        }}
                                    >
                                        <span className="lableFont">Branch</span>
                                        <select onChange={handleChangeByTime} className="selectclass" value={array.byTimeBranch}>
                                            <option value={'Default'}>All Branches</option>
                                            {state.chartdata.map((btn, i) => {
                                                return (
                                                    <option key={i} value={btn.name}>
                                                        {btn.name}{' '}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </Grid>
                            </Grid>
                        </Hidden>

                        <Grid item xs={12} style={{ padding: '5px 5px' }}>
                            <div
                                style={{
                                    textAlign: 'left',
                                    padding: '0px 20px',
                                    fontFamily: ` Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
                                    fontWeight: 500
                                }}
                            ></div>
                        </Grid>
                        <div style={{ padding: '0px 30px' }}>
                            <hr
                                style={{
                                    flexShrink: 0,
                                    borderWidth: '0px 0px thin',
                                    borderStyle: 'solid',
                                    borderColor: 'rgba(58, 53, 65, 0.12)',
                                    margin: '1.1rem 0px'
                                }}
                            />
                        </div>
                        <Grid container spacing={1} style={{ padding: '0px 20px' }}>
                            {getDateObj.map((item, index) => (
                                <Grid
                                    item
                                    xs={6}
                                    sm={2}
                                    style={{ padding: '5px 5px' }}
                                    onClick={() =>
                                        props.onChartClickHandler(
                                            {
                                                workflowID: 'All',
                                                workflow: array.byTimeObj?.workflow,
                                                bn: array.byTimeObj?.bn,
                                                time: item.time,
                                                dueTime: item.dueTime
                                            },
                                            true
                                        )
                                    }
                                >
                                    <div
                                        style={{
                                            borderLeft: item.BarColor,
                                            padding: '5px 0px 0px 20px',
                                            width: '100%',
                                            cursor: 'pointer'
                                        }}
                                        // onClick={() => buttonClicked(m)}
                                    >
                                        <div
                                            style={{
                                                paddingRight: 20,
                                                paddingBottom: 5,
                                                textAlign: 'left'
                                            }}
                                        >
                                            <span className="lableFont">{item.label}</span>
                                            <div className="valueFont">{item.value}</div>
                                        </div>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </Card>
                )}
            </Grid>
            {/* *****************Hours************** */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container style={{ padding: 5, boxSize: 'none' }}>
                <Card className={classes.root}>
                    <Hidden only={['xs']}>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={12} sm={6} style={{ float: 'right' }}>
                                <div
                                    style={{
                                        width: '100%',
                                        textAlign: 'left',
                                        color: '#f50057',
                                        fontWeight: 600,
                                        margin: '10px 20px'
                                    }}
                                >
                                    <ButtonGroup color="secondary" ria-label="outlined primary button group" size="small">
                                        {HoursData.map((n, i) => {
                                            return (
                                                <Button
                                                    key={i}
                                                    variant={array.hourType === n.id ? 'contained' : 'outlined'}
                                                    onClick={() => handleBar(n.id)}
                                                    style={{ fontSize: 10 }}
                                                >
                                                    {n.name}
                                                </Button>
                                            );
                                        })}
                                    </ButtonGroup>
                                </div>
                                {/* <Typography vraient="h5" color="secondary" className="dashHeading">
                                Clocked Hours
                            </Typography> */}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div style={{ float: 'right', marginRight: 40 }}>
                                    <select onChange={handleChangeByTime} className="selectclass" value={array.byTimeBranch}>
                                        <option value={'Default'}>All Branches</option>
                                        {state.chartdata.map((btn, i) => {
                                            return (
                                                <option key={i} value={btn.name}>
                                                    {btn.name}{' '}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Hidden only={['sm', 'xl']}>
                        <Grid container justifyContent="space-between" spacing={0}>
                            <Grid item xs={12} sm={6}>
                                <div
                                    style={{
                                        margin: '0px 20px',
                                        textAlign: 'left'
                                    }}
                                >
                                    <span className="lableFont">Hours</span>
                                    <select
                                        color="secondary"
                                        onChange={handleBar}
                                        aria-label="outlined primary button group"
                                        size="small"
                                        className="ButtonDropDown"
                                        value={array.hourType}
                                    >
                                        {HoursData.map((n, i) => {
                                            return (
                                                <option key={i} value={n.id}>
                                                    {n.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div style={{ margin: '0px 20px', textAlign: 'left' }}>
                                    <span className="lableFont">Branch</span>
                                    <select onChange={handleChangeByTime} className="selectclass" value={array.byTimeBranch}>
                                        <option value={'Default'}>All Branches</option>
                                        {state.chartdata.map((btn, i) => {
                                            return (
                                                <option key={i} value={btn.name}>
                                                    {btn.name}{' '}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </Grid>
                        </Grid>
                    </Hidden>

                    <div style={{ padding: '0px 30px' }}>
                        <hr
                            style={{
                                flexShrink: 0,
                                borderWidth: '0px 0px thin',
                                borderStyle: 'solid',
                                borderColor: 'rgba(58, 53, 65, 0.12)',
                                margin: '1.1rem 0px'
                            }}
                        />
                    </div>
                    <Grid container spacing={0} style={{ padding: '0px 20px' }} justifyContent="space-between">
                        {state.showClokedHours ? (
                            <div className={classes.root}>
                                <div className="pieChartSize" style={{ display: 'flex' }}>
                                    <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto', alignSelf: 'center' }} />
                                </div>
                            </div>
                        ) : (
                            getHoursObj.length > 0 &&
                            getHoursObj.map((g) => {
                                if ((state.selectedBranch == 'Default' && g.bn == 'Default') || g.bn == state.selectedBranch) {
                                    const { bn, branchID, hoursTaken = 0, orderIndex, workflow, workflowID, hoursAllowed = 0, hoursRemaining = 0 } = g;
                                    return (
                                        <div>
                                            <Grid
                                                item
                                                style={{ padding: '5px 0px' }}
                                                onClick={() =>
                                                    props.onChartClickHandler(
                                                        { workflowID: workflowID, workflow: workflow, ...(state.selectedBranch != 'Default' && { bn: bn }) },
                                                        true
                                                    )
                                                }
                                            >
                                                <div style={{ borderLeft: '5px solid #88EBA4', padding: '5px 0px 0px 20px', width: '100%', cursor: 'pointer' }}>
                                                    <div style={{ paddingRight: 20, paddingBottom: 5, textAlign: 'left' }}>
                                                        <span className="lableFont">{workflow}</span>
                                                        <div className="valueFont">
                                                            {array.hourType === 'clocked'
                                                                ? hoursTaken?.toFixed(2)
                                                                : array.hourType === 'allowed'
                                                                ? hoursAllowed?.toFixed(2)
                                                                : hoursRemaining.toFixed(2)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </div>
                                    );
                                }
                            })
                        )}
                    </Grid>
                </Card>
            </Grid>
            {/* ********WorkFlow Graph******* */}
            {state.showHeaderLoader ? (
                <div className={classes.root} style={{ width: '100%', height: 300, display: 'flex' }}>
                    <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto', alignSelf: 'center' }} />
                </div>
            ) : (
                state?.allHeaderData?.map((m, i) => {
                    if (m.bn === array.timeByBranch) {
                        return (
                            <Grid
                                item
                                container
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                // xl={6}
                                xl={state.workflowData?.length > 6 ? 12 : 6}
                                style={{ padding: 5 }}
                            >
                                <Card className={classes.root}>
                                    <Hidden only={['xs']}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item xs={12} sm={8} md={8} lg={state.workflowData?.length > 6 ? 10 : 8}>
                                                <Typography vraient="h5" color="secondary" className="dashHeading">
                                                    {`Workflows By Branch`}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <div style={{ marginRight: 40 }}>
                                                    <select onChange={handleTimeBranch} className="selectclass" value={array.timeByBranch}>
                                                        {state.chartdata.map((btn, i) => {
                                                            return (
                                                                <option key={i} value={btn.name}>
                                                                    {btn.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Hidden>
                                    <Hidden only={['sm', 'xl']}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item xs={12} sm={8} md={8} lg={10}>
                                                <Typography variant="h7" color="secondary" style={{ fontWeight: 600 }}>
                                                    {`Workflows By Branch`}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <div
                                                    style={{
                                                        margin: '0px 20px',
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <span className="lableFont">Branch</span>
                                                    <select onChange={handleTimeBranch} className="selectclass" value={array.timeByBranch}>
                                                        {state.chartdata.map((btn, i) => {
                                                            return (
                                                                <option key={i} value={btn.name}>
                                                                    {btn.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Hidden>
                                    <div className="workflowChart">
                                        <ResponsiveContainer>
                                            <BarChart
                                                data={m}
                                                margin={{
                                                    top: 20,
                                                    right: width >= 600 ? 20 : 10,
                                                    left: width >= 600 ? -8 : -30,
                                                    bottom: width >= 600 ? 0 : 10
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis
                                                    dataKey="workflow"
                                                    textAnchor="end"
                                                    sclaeToFit="true"
                                                    verticalAnchor="start"
                                                    allowDataOverflow={false}
                                                    interval={0}
                                                    // dy={-5}
                                                    angle={width >= 600 ? '0' : '-20'}
                                                    style={{
                                                        fontSize: width >= 600 ? 12 : 6,
                                                        fontWeight: 700
                                                    }}
                                                />
                                                <YAxis style={{ fontSize: 12 }} />
                                                <Tooltip
                                                    contentStyle={{
                                                        background: 'rgba(0,0,0,0.8)',
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontWeight: 700
                                                    }}
                                                    position={{ x: 30, y: 20 }}
                                                />
                                                {width >= 600 ? <Legend /> : null}
                                                {state?.allHeaderDataKeys?.map((n, i) => {
                                                    return (
                                                        n !== 'name' &&
                                                        n !== 'workflow' &&
                                                        n !== 'workflowID' && (
                                                            <Bar dataKey={n} fill={setObject[n]} barSize={10} style={{ cursor: 'pointer' }}>
                                                                {/* <LabelList dataKey={n} position="top" /> */}
                                                                {m.map((entry, index) => {
                                                                    return (
                                                                        <Cell
                                                                            onClick={() =>
                                                                                props.onChartClickHandler(
                                                                                    {
                                                                                        workflowID: entry.workflowID,
                                                                                        workflow: entry.workflow,
                                                                                        bn: entry.name,
                                                                                        time: timenumber[n]
                                                                                    },
                                                                                    true
                                                                                )
                                                                            }
                                                                        />
                                                                    );
                                                                })}
                                                            </Bar>
                                                        )
                                                    );
                                                })}
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                </Card>
                            </Grid>
                        );
                    }
                })
            )}
            {/* ********* Return Reason By Workflow ********* */}
            <Grid item container xs={12} sm={12} xl={state.workflowData?.length > 6 ? 12 : 6} style={{ padding: 5, boxSize: 'none' }}>
                {state.showReturnReasonLoader ? (
                    <div className={classes.root} style={{ width: '100%', height: 300, display: 'flex' }}>
                        <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto', alignSelf: 'center' }} />
                    </div>
                ) : (
                    <Card className={classes.root}>
                        <Hidden only={['xs']}>
                            <Grid container justifyContent="space-between">
                                <Grid item xs={12} sm={8} lg={8}>
                                    <Typography vraient="h5" color="secondary" className="dashHeading">
                                        Return Reason By Workflow ({!array?.byTimeBranch ? 'All Branch' : array?.byTimeBranch})
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4} lg={4}>
                                    <div style={{ float: 'right', marginRight: 40 }}>
                                        <select onChange={handleArray} className="selectclass" value={array.workFlowRR}>
                                            {state?.workflowData.map((j, i) => (
                                                <option key={i} value={j.name}>
                                                    {j.name}{' '}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </Grid>
                            </Grid>
                        </Hidden>
                        <Hidden only={['sm', 'xl']}>
                            <Grid container justifyContent="space-between">
                                <Grid item xs={12} sm={8} md={8} lg={10}>
                                    <Typography variant="h7" color="secondary" style={{ fontWeight: 600 }}>
                                        <Typography vraient="h5" color="secondary" className="dashHeading">
                                            Return Reason By Workflow ({!array?.byTimeBranch ? 'All Branch' : array?.byTimeBranch})
                                        </Typography>
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <div
                                        style={{
                                            margin: '0px 20px',
                                            textAlign: 'left'
                                        }}
                                    >
                                        <span className="lableFont">Workflow</span>
                                        <select onChange={handleArray} className="selectclass" value={array.workFlowRR}>
                                            {state?.workflowData.map((j, i) => (
                                                <option key={i} value={j.name}>
                                                    {j.name}{' '}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </Grid>
                            </Grid>
                        </Hidden>

                        <div className="workflowChart">
                            {array.workflowReturnReasonArray.length > 0 ? (
                                <ResponsiveContainer>
                                    <BarChart
                                        data={array.workflowReturnReasonArray}
                                        width={300}
                                        margin={{
                                            top: 20,
                                            right: width >= 600 ? 20 : 10,
                                            left: width >= 600 ? -8 : -30,
                                            bottom: width >= 600 ? 0 : 10
                                        }}
                                        // contentStyle={{ background: 'rgba(0,0,0,0.6)' }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="workflow"
                                            verticalAnchor="start"
                                            allowDataOverflow={false}
                                            interval={0}
                                            // dy={-5}
                                            // angle={width >= 600 ? '0' : '-20'}
                                            style={{
                                                fontSize: 12,
                                                fontWeight: 700
                                            }}
                                        >
                                            {/* <Label value="Pages of my website" offset={-4} position="insideBottom" /> */}
                                        </XAxis>
                                        <YAxis style={{ fontSize: 12 }} />
                                        <Tooltip
                                            contentStyle={{
                                                background: 'rgba(0,0,0,0.8)',
                                                color: 'white',
                                                fontSize: 12,
                                                fontWeight: 700
                                            }}
                                            position={{ x: 30, y: 20 }}
                                        />
                                        {/* <Legend height={10} /> */}

                                        {array?.keys?.map((m, i) => {
                                            let color = state?.returnReasonColors.find((n) => n.rr === m);
                                            return (
                                                m !== 'name' &&
                                                m !== 'workflowID' &&
                                                m !== 'workflow' && (
                                                    <Bar dataKey={m} fill={color.color} barSize={width >= 600 ? 40 : 15} layout="vertical" height={20}>
                                                        <LabelList dataKey={m} position="top" />
                                                        {array.workflowReturnReasonArray.map((entry, index) => {
                                                            return (
                                                                <Cell
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() =>
                                                                        props.onChartClickHandler(
                                                                            {
                                                                                workflowID: entry.workflowID,
                                                                                returnReason: m,
                                                                                bn: entry.name
                                                                            },
                                                                            true
                                                                        )
                                                                    }
                                                                />
                                                            );
                                                        })}
                                                    </Bar>
                                                )
                                            );
                                        })}
                                    </BarChart>
                                </ResponsiveContainer>
                            ) : (
                                <Grid container justifyContent="center" alignContent="center">
                                    <h5>No chart data Found</h5>
                                </Grid>
                            )}
                        </div>
                    </Card>
                )}
            </Grid>
            {/* *************Jobs By Technicians*************ss */}
            <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 5, boxSize: 'none' }}>
                {state.showTechnicianLoader ? (
                    <div className={classes.root} style={{ width: '100%', minHeight: '110px', display: 'flex' }}>
                        <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto', alignSelf: 'center' }} />
                    </div>
                ) : (
                    <Card className={classes.root}>
                        <Hidden only={['xs']}>
                            <Grid container xs={12}>
                                <Grid item xs={12} sm={6}>
                                    <Typography vraient="h5" color="secondary" className="dashHeading">
                                        {'Jobs By Technicians'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div style={{ float: 'right', marginRight: 40, display: 'flex' }}>
                                        <div
                                            style={{
                                                padding: '2px 12px 0px 0px',
                                                fontSize: 20,
                                                fontWeight: 600,
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <span
                                                onClick={() =>
                                                    handleTechOnClick({
                                                        tid: '',
                                                        bn: array.selectedValueByTech,
                                                        name: '',
                                                        bid: ''
                                                    })
                                                }
                                            >
                                                Total : {array.techJobsTotal}
                                            </span>
                                        </div>
                                        <div>
                                            <select onChange={handleTechSelect} className="selectclass" value={array.selectedValueByTech}>
                                                {state.chartdata.map((btn, i) => {
                                                    return (
                                                        <option key={i} value={btn.name}>
                                                            {btn.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Hidden>
                        <Hidden only={['sm', 'xl']}>
                            <Grid container justifyContent="space-between">
                                <Grid item xs={12} sm={8} md={8} lg={10}>
                                    <Typography variant="h7" color="secondary" style={{ fontWeight: 600 }}>
                                        <Typography vraient="h5" color="secondary" className="dashHeading">
                                            Jobs By Technicians &nbsp; &nbsp;Total : {array.techJobsTotal}
                                        </Typography>
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <div
                                        style={{
                                            margin: '0px 20px',
                                            textAlign: 'left'
                                        }}
                                    >
                                        <span className="lableFont">Branch</span>
                                        <select onChange={handleTechSelect} className="selectclass" value={array.selectedValueByTech}>
                                            {state.chartdata.map((btn, i) => {
                                                return (
                                                    <option key={i} value={btn.name}>
                                                        {btn.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </Grid>
                            </Grid>
                        </Hidden>

                        {/* <Grid container xs={12}>
                            <Grid item xs={12} sm={6}>
                                <Typography vraient="h5" color="secondary" className="dashHeading">
                                    {'Jobs By Technicians'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div style={{ float: 'right', marginRight: 40, display: 'flex' }}>
                                    <div
                                        style={{
                                            padding: '2px 12px 0px 0px',
                                            fontSize: 20,
                                            fontWeight: 600,
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <span
                                            onClick={() =>
                                                handleTechOnClick({
                                                    tid: '',
                                                    bn: array.selectedValueByTech,
                                                    name: '',
                                                    bid: ''
                                                })
                                            }
                                        >
                                            Total : {array.techJobsTotal}
                                        </span>
                                    </div>
                                    <div>
                                        <select onChange={handleTechSelect} className="selectclass" value={array.selectedValueByTech}>
                                            {state.chartdata.map((btn, i) => {
                                                return (
                                                    <option key={i} value={btn.name}>
                                                        {btn.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </Grid>
                        </Grid> */}

                        <Grid item xs={12} style={{ padding: '5px 5px ' }}>
                            <div
                                style={{
                                    textAlign: 'left',
                                    padding: '0px 20px',
                                    fontFamily: ` Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
                                    fontWeight: 500
                                }}
                            ></div>
                        </Grid>
                        <div style={{ padding: '0px 30px' }}>
                            <hr
                                style={{
                                    flexShrink: 0,
                                    borderWidth: '0px 0px thin',
                                    borderStyle: 'solid',
                                    borderColor: 'rgba(58, 53, 65, 0.12)',
                                    margin: '1.1rem 0px'
                                }}
                            />
                        </div>
                        <Grid container spacing={2} style={{ minHeight: '110px', overflow: 'auto' }}>
                            {array.selectedFilterTechData.map((p) => {
                                return (
                                    <Grid item xs={6} sm={4} md={4} lg={3} xl={2} onClick={() => handleTechOnClick(p)} style={{ cursor: 'pointer' }}>
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item>
                                                <Avatar
                                                    style={{
                                                        backgroundColor: randomColor()
                                                    }}
                                                >
                                                    {p.name[0]}
                                                </Avatar>
                                            </Grid>
                                            <Grid item>
                                                <div
                                                    style={{
                                                        paddingBottom: 10,
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <span className="lableFont"> {p.name}</span>
                                                    <div className="valueFont">{p.total}</div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Card>
                )}
            </Grid>
        </Grid>
    );
};

export default DashboardCharts;
