import { Breadcrumbs, IconButton, Link, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { GridOverlay } from '@material-ui/data-grid';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../../App/AppContext';
import { SecondaryButton } from '../../../../Core/FormInput';
import DataGridComp from '../../../../Core/Grid';
import DialogComp from '../../../../Core/Controls/Dialog/DialogComp';
import AddEditCashAccountTypes from './AddEdit';
import { getCashAccountTypes } from '../../../../Core/Service/CashAccount-service';

const CashAccountTypes = (props) => {
    const [state, setState] = useState({
        rows: [],
        show: false,

        focusedRow: null,
    });

    const { showToast } = useContext(AppContext);

    const Arrow = (params) => {
        return (
            <>
                <Tooltip title="Update Cash Account Type" aria-label="add">
                    <IconButton size="small" onClick={() => handleAdd(true, params)}>
                        <EditOutlinedIcon color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    const column_new = [
        {
            field: '',
            headerName: 'Action',
            sortable: false,
            width: 120,
            renderCell: (params) => <Arrow value={params} />
        },
        {
            field: 'id',
            headerName: 'ID',
            width: 200,
            hide: true
        },
        
        {
            field: 'cashAccountCategoryDescription',
            headerName: 'Category',
            flex: 1
        },
        {
            field: 'cashAccountTypeCategoryID',
            headerName: 'Category ID',
            flex: 1,
            hide: true,
        },
        {
            field: 'cashAccountTypeDescription',
            headerName: 'Description',
            flex: 1
        },
        {
            field: 'cashAccountTypeAccountCodes',
            headerName: 'Account Codes',
            flex: 1
        },
        {
            field: 'cashAccountTypeSortOrder',
            headerName: 'Sort Order',
            flex: 1
        }
    ];

    useEffect(async () => {
        pullCashAccountTypesAndUpdateState()
    }, []);

    const pullCashAccountTypesAndUpdateState = async () => {
        const res = await getCashAccountTypes()
        if (res.success) {

            const rows = res.data.map((e) => {
                return {
                    id: e.cashAccountTypeID,
                    cashAccountTypeCategoryID: e.cashAccountTypeCategoryID,
                    cashAccountTypeDescription: e.cashAccountTypeDescription,
                    cashAccountTypeAccountCodes: e.cashAccountTypeAccountCodes,
                    cashAccountTypeSortOrder: e.cashAccountTypeSortOrder,
                    cashAccountCategoryDescription: e.cashAccountCategoryDescription,
                }
            });

            setState((st) => {
                return {
                    ...st,
                    rows: rows
                };
            });
        }
    };

    const handleAdd = (va, data) => {
        setState((st) => {
            return {
                ...st,
                show: va,
                focusedRow: data?.value?.row ? data.value.row : null
            };
        });
    };

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No Cash Account Types Available</div>
            </GridOverlay>
        );
    }

    const closeUpdateUser = (refreshGrid) => {
        setState((st) => ({ ...st, show: false, focusedRow: null }));
        refreshGrid && pullCashAccountTypesAndUpdateState();
    };

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', right: 0 }}>
                <SecondaryButton variant="contained" color="secondary" onClick={() => handleAdd(true, null)} className="btnadd">
                    Add Cash Account Type
                </SecondaryButton>
            </div>
            {state.show ?
                <DialogComp title={`${state.focusedRow ? "Update" : "Add"} Cash Account Type`} onClose={() => handleAdd(false, null)} maxWidth="sm">
                    <AddEditCashAccountTypes onClose={closeUpdateUser} focusedRow={state.focusedRow} />
                </DialogComp>
                : null}
            <Breadcrumbs separator=">>" aria-label="breadcrumb">
                <Link color="inherit" to={'/'}>
                    Home
                </Link>
                <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                    Cash Types
                </Typography>
            </Breadcrumbs>

            <DataGridComp rows={state.rows} columns={column_new} enableSearch={true} offset={244} CustomNoRowsOverlay={CustomNoRowsOverlay} />
        </div>
    );
};
export default CashAccountTypes;
