import { get, post, put } from './http-calls';

export const getAllUsers = async () => {
    let res = await get('/users', { useAuthToken: true });
    return res;
};

export const getUser = async (data) => {
    let res = await get(`/users/${data}`, { useAuthToken: true });
    return res;
};

export const getHolidaysScreenDropdown = async (data) => {
    let res = await get(`Holidays/ScreenDropdown`, { useAuthToken: true });
    return res;
};

export const getHolidaysList = async (Year) => {
    let res = await get(`Holidays?Year=${Year}`, { useAuthToken: true });
    return res;
};

export const AddHolidays = async (data) => {
    let res = await post('Holidays', data, { useAuthToken: true });
    return res;
};

export const getScreenDropdown = async () => {
    let res = await get(`/ScreenDropdown`, { useAuthToken: true });
    return res;
};
export const Adduser = async (data) => {
    let res = await post('/register', data, { useAuthToken: true });
    return res;
};

export const UpdateUser = async (data) => {
    let res = await put(`/users/${data.id}`, data, { useAuthToken: true });
    return res;
};

export const ResetPassByAdmin = async (userId, newpassword, confirm) => {
    let res = await post(
        `/overwritePassword/${userId}`,
        {
            newPassword: newpassword,
            confirmPassword: confirm
        },
        { useAuthToken: true }
    );
    return res;
};
