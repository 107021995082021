import React from 'react';
import moment from 'moment';
import { Grid, FormLabel, Breadcrumbs, InputLabel } from '@material-ui/core';
import { TextBox } from '../../../../../Core/FormInput';
// import NestedGijgoGrid from './../../../../Core/Controls/GijgoGrid/nestedGijgoGrid';
import { Link } from 'react-router-dom';
// import BreadCrumbs from './../../../../Core/Controls/Breadcrumb';
import NestedGijgoGrid from '../../../../../Core/Controls/GijgoGrid/nestedGijgoGrid';
import BreadCrumbs from '../../../../../Core/Controls/Breadcrumb/index';
import formatters from '../../../../../Core/Grid/inputFormatter';
import { useState } from 'react';
import { useEffect } from 'react';
import { getReportsSalesDropdownsResolveData } from '../../../../../Core/Service/reportService';

var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
let addCommas = (val) => {
    return val ? `${val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '0.00';
};

const baseColumns = [
    { title: 'Stock No', field: 'sn', width: 50, sortable: true },
    { title: 'Comm Number', field: 'cn', width: 70, sortable: true },
    { title: 'Reg Number', field: 'rn', width: 60, sortable: true },
    // {
    //     title: 'Progress',
    //     field: 'pd',
    //     width: 80,
    //     sortable: true,
    //     isDropDown: true,
    //     listIdFromAPIResponse: 'progressCodes',
    //     dropDownValueField: 'id',
    //     dropDownLableField: 'name'
    // },
    {
        title: 'Model',
        field: 'mc',
        width: 70,
        sortable: true,
        isDropDown: true,
        listIdFromAPIResponse: 'modelRecords',
        dropDownValueField: 'name',
        dropDownLableField: 'name'
    },
    {
        title: 'Stock Days',
        field: 'd',
        width: 50,
        sortable: true,
        // renderer: formatters.MonthShortFormatter,
        cssClass: 'text-center'
    },
    {
        title: 'Type',
        field: 'mac',
        width: 50,
        sortable: true,
        isDropDown: true,
        listIdFromAPIResponse: 'modelAnalysisCodes',
        dropDownValueField: 'name',
        dropDownLableField: 'name'
    },
    {
        title: 'Branch',
        field: 'bn',
        width: 70,
        sortable: true,
        isDropDown: true,
        listIdFromAPIResponse: 'branchList',
        dropDownValueField: 'name',
        dropDownLableField: 'name'
    },
    {
        title: 'Customer',
        field: 'cust',
        width: 130,
        sortable: true
    },
    {
        title: 'Sold By',
        field: 'sp',
        width: 100,
        sortable: true
    },
    {
        title: 'Latest Profit',
        field: 'latestProfitValue',
        renderer: formatters.CurrencyThousandSeparator,
        width: 120,
        sortable: true,
        align: 'right'
    },
    {
        title: 'Vehicle Description',
        field: 'vd',
        width: 170
    },
    {
        title: 'Account Status',
        field: 'accStatus',
        width: 80
    },
    {
        title: 'Reg Date',
        field: 'regDate',
        width: 80,
        renderer: formatters.MonthShortFormatter
    }
    // {
    //     title: 'Cost',
    //     field: 'cost',
    //     renderer: formatters.CurrencyThousandSeparator,
    //     width: 70,
    //     sortable: true,
    //     align: 'right'
    // },
    // {
    //     title: 'Order Date',
    //     field: 'od',
    //     width: 70,
    //     sortable: true,
    //     renderer: formatters.MonthShortFormatter,
    //     align: 'right'
    // },
    // {
    //     title: 'Build Date',
    //     field: 'bd',
    //     width: 70,
    //     sortable: true,
    //     renderer: formatters.MonthShortFormatter,
    //     align: 'right'
    // },
    // {
    //     title: 'Cons Date',
    //     field: 'cd',
    //     width: 90,
    //     sortable: true,
    //     renderer: formatters.MonthShortFormatter,
    //     align: 'right'
    // },
    // {
    //     title: 'Est Date',
    //     field: 'EstimDeliveryDate',
    //     renderer: formatters.MonthShortFormatter,
    //     width: 70,
    //     sortable: true,
    //     align: 'right'
    // }
];

const detailTemplate = () => {
    return (
        "<div style='width:100%'><table style='width:100%;'>" +
        "<tr style='background-color:#DCDCDC;'>" +
        "<th style='font-weight:750; text-align:left;'>Order Date</th >" +
        "<th style='font-weight:750; text-align:left;'>Build Date</th>" +
        "<th style='font-weight:750; text-align:left;'>Cons Date</th>" +
        "<th style='font-weight:750; text-align:left;'>Est Date</th>" +
        "<th style='font-weight:750; text-align:left;'>Est Profit</th></tr>" +
        '<tr><td>{od}</td><td>{bd}</td><td>{cd}</td><td>{estimDeliveryDate}</td><td>£{estimatedProfit}</td></tr></div>'
    );
};

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Sales', active: true },
    { name: 'Registration Report', active: true }
];

// let startDate = ;

const RegistrationReports = (props) => {
    const [state, setState] = useState({
        EndDate: moment().format('YYYY-MM-DD'),
        StartDate: moment().startOf('month').format('YYYY-MM-DD')
    });
    const handleInput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    const baseUrl = `Sales/RegistrationReport?StartDate=${state.StartDate}&EndDate=${state.EndDate}`;

    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs} />
            <Grid container spacing={3}>
                <Grid item xs={6} md={4} lg={2}>
                    <InputLabel shrink>Start Date</InputLabel>
                    <TextBox type="date" name="StartDate" value={state.StartDate} onChange={handleInput} />
                </Grid>
                <Grid item xs={6} md={4} lg={2}>
                    <InputLabel shrink>End Date</InputLabel>
                    <TextBox type="date" name="EndDate" value={state.EndDate} onChange={handleInput} />
                </Grid>
            </Grid>
            <NestedGijgoGrid
                dataConverter={(res) => ({
                    records: res?.data?.list,
                    total: res?.data?.total
                })}
                columns={baseColumns}
                getUrl={baseUrl}
                displayPagination={true}
                detailTemplate={detailTemplate}
                downloadName={'Registration Report'}
                dropDownListProvider={getReportsSalesDropdownsResolveData}
            />
        </div>
    );
};

export default RegistrationReports;
