import React, { useContext, useMemo, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { getMenuForRole } from './_menu';
import AppbarComponent from './Appbar';
import AppContext from '../../App/AppContext';
import { WorkflowDash, PartSales } from '../../Components';
import { AppStorage } from '../Service/storage-service';
import InternalMenusToConvertDynamic from '../Root/menu/roles-menu/reportingMenu';

export let Navbar = (props) => {
    const { loggedIn, userRoles, showTopNavAndMenu, tempMenus, portalSettings, reportingMenus } = useContext(AppContext);
    const isCommunicatorAllowed = AppStorage.getCommunicatorAllowed();
    const isInvoiceAllowed = AppStorage.getInvoiceAllowed();
    const isReportingAllowed = AppStorage.getReportingAllowed();
    const isWIPAllowed = AppStorage.getWIPAllowed() && portalSettings.portalSettingeWIPEnabled;
    let menuPermissionCounter = isCommunicatorAllowed + isInvoiceAllowed + isReportingAllowed + isWIPAllowed;
    const isReportingAgedMeasureAllowed = AppStorage.getReportingAgedMeasureAllowed();
    const isReportingPartsAllowed = AppStorage.getReportingPartsAllowed();

    const currentMenus = useMemo(() => {
        let menus = getMenuForRole(userRoles, loggedIn);
        if (isWIPAllowed) {
            let dashMenus = [];
            if (tempMenus.menus.length > 0) {
                dashMenus = tempMenus.menus.map((x) => {
                    return {
                        label: `${x.label}`,
                        path: `/dashboard/${x.id}`,
                        exact: true,
                        authType: 1,
                        component: () => {
                            return <WorkflowDash workFlowId={x.id} />;
                        }
                    };
                });
            }
            if (menuPermissionCounter == 1) {
                let isDash = menus.findIndex((p) => p.isDynamicChildren === 'eWipDashboard');
                if (tempMenus.menus.length > 0 && isDash >= 0) {
                    menus[isDash].children = dashMenus;
                }
            } else {
                let iseWIPMenu = menus.findIndex((p) => p.label === 'eWIP');
                let isDash = menus[iseWIPMenu].children.findIndex((p) => p.isDynamicChildren === 'eWipDashboard');
                if (tempMenus.menus.length > 0 && isDash >= 0) {
                    menus[iseWIPMenu].children[isDash].children = dashMenus;
                }
            }
        }
        if (isReportingAllowed) {
            let ReportsIndex = InternalMenusToConvertDynamic.findIndex((m) => m.label == 'Reports');
            let findAgedMeasureMenuIndex = InternalMenusToConvertDynamic[ReportsIndex]?.children?.findIndex((m) => m.label == 'Aged Measures');
            let tempAgedMeasureChildren = InternalMenusToConvertDynamic[ReportsIndex]?.children[findAgedMeasureMenuIndex]?.children;
            const agedMeasureMenus = [];
            const PartsMenus = [];

            if (reportingMenus.menus?.length > 0) {
                let menusFromAPI = reportingMenus.menus;
                if (tempAgedMeasureChildren?.length > 0) {
                    for (let j = 0; j < menusFromAPI.length; j++) {
                        for (let i = 0; i < tempAgedMeasureChildren?.length; i++) {
                            if (tempAgedMeasureChildren[i].label === menusFromAPI[j].label) {
                                agedMeasureMenus.push({
                                    ...tempAgedMeasureChildren[i],
                                    path: menusFromAPI[j].path,
                                    label: menusFromAPI[j].menuLabel
                                });
                            }
                        }
                    }
                }
                for (let j = 0; j < menusFromAPI.length; j++) {
                    if (menusFromAPI[j].parentMenu == 'Part') {
                        PartsMenus.push({
                            exact: true,
                            authType: 1,
                            path: menusFromAPI[j].path,
                            label: menusFromAPI[j].menuLabel,
                            component: () => {
                                return <PartSales />;
                            }
                        });
                    }
                }
            }
            let isReportingMenu = menus.findIndex((p) => p.label === 'Reports');
            if (isReportingMenu > -1) {
                if (isReportingAgedMeasureAllowed || isReportingPartsAllowed) {
                    let isReportingMenu = menus.findIndex((p) => p.label === 'Reports');
                    if (isReportingMenu >= 0) {
                        let agedMeasuresIndex = menus[isReportingMenu].children.findIndex((p) => p.label === 'Aged Measures');
                        let partsIndex = menus[isReportingMenu].children.findIndex((p) => p.label === 'Parts');
                        if (isReportingAgedMeasureAllowed && agedMeasuresIndex >= 0) {
                            menus[isReportingMenu].children[agedMeasuresIndex].children = agedMeasureMenus.length > 0 ? agedMeasureMenus : [];
                        }
                        if (isReportingPartsAllowed && partsIndex >= 0) {
                            menus[isReportingMenu].children[partsIndex].children = PartsMenus.length > 0 ? PartsMenus : [];
                        }
                    }
                }
            }
        }
        return menus;
    }, [loggedIn, tempMenus, reportingMenus]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search.toLowerCase());
        const fromStep = urlParams.get('fromstep') === 'true';
        console.log(fromStep, 'fromStep');
        console.log(loggedIn, 'loggedIn');
        if (fromStep && !loggedIn) {
            console.log('Log Out trigger from Navbar.jsx passed trigger-logout to app.jsx');
            window.top.postMessage({ type: 'trigger-logout' }, '*');
        }
    }, []);

    return (
        <>
            {showTopNavAndMenu && <AppbarComponent />}
            <div className="component-container">
                <Switch>
                    {currentMenus.map((m, i) => {
                        return m.children ? (
                            m.children.map((a) => {
                                return a.children ? (
                                    a.children.map((b) => {
                                        const [url, params] = b.path.split('?');
                                        return <Route key={b.label} exact={b.exact} path={url} component={b.component}></Route>;
                                    })
                                ) : (
                                    <Route key={a.label} exact={a.exact} path={a.path} component={a.component}></Route>
                                );
                            })
                        ) : (
                            <Route key={m.label} exact={m.exact} path={m.path} component={m.component}></Route>
                        );
                    })}
                </Switch>
            </div>
        </>
    );
};
