import React, { useState } from 'react';
import { Grid, InputLabel } from '@material-ui/core';
import BreadCrumbs from './../../../../../Core/Controls/Breadcrumb/index';
import formatters from '../../../../../Core/Grid/inputFormatter';
import ReportGijgoGrid from '../../GijgoForReportsScreen';
import { getReportsSalesDropdownsResolveData } from '../../../../../Core/Service/reportService';
import { TextBox } from '../../../../../Core/FormInput';
import moment from 'moment';

const baseColumns = [
    { title: 'Stock No', field: 'sn', width: 80, sortable: true },
    { title: 'Status', field: 's', width: 80, sortable: true },
    {
        title: 'Progress',
        field: 'pd',
        width: 80,
        sortable: true,
        isDropDown: true,
        listIdFromAPIResponse: 'progressCodes',
        dropDownValueField: 'id',
        dropDownLableField: 'name'
    },
    {
        title: 'Model',
        field: 'mc',
        width: 90,
        sortable: true,
        isDropDown: true,
        listIdFromAPIResponse: 'modelRecords',
        dropDownValueField: 'name',
        dropDownLableField: 'name'
    },
    {
        title: 'Vehicle Description',
        field: 'vd',
        width: 250,
        sortable: true,

        cssClass: 'text-center'
    },
    {
        title: 'Comm Number',
        field: 'cn',
        width: 110,
        sortable: true
    },

    {
        title: 'Cons Date',
        field: 'cd',
        width: 90,
        sortable: true,

        renderer: function (value, record) {
            return formatters.MonthShortFormatter(value);
        }
    },
    {
        title: 'Build Date',
        field: 'bd',
        width: 90,
        sortable: true,

        renderer: function (value, record) {
            return formatters.MonthShortFormatter(value);
        }
    },
    {
        title: 'Days',
        field: 'd',
        width: 90,
        sortable: true
    },
    {
        title: 'Sold By',
        field: 'bn',
        width: 100,
        sortable: true,
        isDropDown: true,
        listIdFromAPIResponse: 'branchList',
        dropDownValueField: 'name',
        dropDownLableField: 'name'
    },
    {
        title: 'Notes',
        field: 'n',
        width: 150,
        sortable: true
    },
    {
        title: 'Latest Loc',
        field: 'rsc',
        width: 90,
        sortable: true
        // renderer: function (value, record) {
        //     return addCommas(value);
        // }
    },
    {
        title: 'Vehicle Group',
        field: 'mac',
        width: 100,
        sortable: true,
        isDropDown: true,
        listIdFromAPIResponse: 'modelAnalysisCodes',
        dropDownValueField: 'name',
        dropDownLableField: 'name'
    }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Sales', active: true },
    { name: 'New Vehicle Orders', active: true }
];

const VehicleNewOrder = (props) => {
    const [state, setState] = useState({
        EndDate: moment().format('YYYY-MM-DD'),
        StartDate: moment().startOf('month').format('YYYY-MM-DD')
    });
    const handleInput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let PramMC = params.get('mc') || '';

    const baseUrl = `Sales/NewVehicleOrders?StartDate=${state.StartDate}&EndDate=${state.EndDate}`;
    return (
        <div className="report-screen-container">
            <BreadCrumbs crumbs={crumbs} />
            <Grid container spacing={3}>
                <Grid item xs={6} md={4} lg={3}>
                    <InputLabel shrink>Start Date</InputLabel>
                    <TextBox type="date" name="StartDate" value={state.StartDate} onChange={handleInput} />
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                    <InputLabel shrink>End Date</InputLabel>
                    <TextBox type="date" name="EndDate" value={state.EndDate} onChange={handleInput} />
                </Grid>
            </Grid>

            <ReportGijgoGrid
                dataConverter={(res) => ({
                    records: res?.data?.list,
                    total: res?.data?.total
                })}
                columns={baseColumns}
                getUrl={baseUrl}
                displayPagination={true}
                FilterdParamsValues={{ mc: PramMC }}
                downloadName={'New Vehicle Orders'}
                dropDownListProvider={getReportsSalesDropdownsResolveData}
            />
        </div>
    );
};

export default VehicleNewOrder;
