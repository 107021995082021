import { get, post } from './http-calls';

export const getJobUserAssigned = async (HeaderRecordId) => {
    let res = await get(`JobUserAssigned?HeaderRecordId=${HeaderRecordId}`, { useAuthToken: true });
    return res;
};

export let uploadJobUserAssigned = async (formData) => {
    let data = await post(`JobUserAssigned`, formData, { useAuthToken: true });
    return data;
};

export const getWorkflowJobs = async (workflowId) => {
    let res = await get(`WorkflowJobs/${workflowId}`, { useAuthToken: true });
    return res;
};

export const getWorkflowDashBoard = async (workflowId) => {
    let res = await get(`DashBoard/Internal/${workflowId}`, { useAuthToken: true });
    return res;
};
