import { CircularProgress, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useState } from 'react';
import formatters from '../../../../Core/Grid/inputFormatter';
import { getReportingInternalHighLevelDashboard } from '../../../../Core/Service/ReportScreens-service';
import './miniStyle.scss';
import { useHistory } from 'react-router-dom';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { VehicleCard } from './ministyleComp';
const MiniViewReportingDashboard = (props) => {
    const [state, setState] = useState({ data: {}, parts: [], isLoader: true });
    const history = useHistory();
    const handleRedirect = () => {
        let link = '/RepotingTab';
        history.push({
            pathname: link
        });
    };

    useEffect(async () => {
        let res = await getReportingInternalHighLevelDashboard();
        setState((st) => ({
            ...st,
            data: res.data.data[0],
            parts: res.data.parts || [],
            isLoader: false
        }));
    }, []);

    // return (
    //     // <Grid
    //     //     container
    //     //     style={{
    //     //         // padding: '10px 0px',
    //     //         height: 'calc(100vh - 105px)',
    //     //         overflow: 'auto'
    //     //     }}
    //     //     justifyContent="center"
    //     //     className="salesBudgetScreen"
    //     //     alignItems="center"
    //     // >
    //     //     {state.isLoader ? (
    //     //         <CircularProgress color="secondary" />
    //     //     ) : (
    //     //         <Grid item sm={12} xl={11}>
    //     //             <Grid container spacing={2}>
    //     //                 <Grid item xs={12}>
    //     //                     <Typography variant="h5" color="secondary" style={{ textAlign: 'left', fontWeight: 700 }}>
    //     //                         After Sales (MTD)
    //     //                     </Typography>
    //     //                     {/* <hr style={{ color: 'black' }} /> */}
    //     //                 </Grid>
    //     //                 <Grid item xs={12} className="mainContainer">
    //     //                     <Grid container spacing={2}>
    //     //                         <Grid item xs={12} sm={6} xl={2} style={{ cursor: 'pointer' }} onClick={() => handleRedirect()}>
    //     //                             <Grid container justifyContent="space-around" alignItems="center" spacing={2}>
    //     //                                 <Grid item xs={12}>
    //     //                                     <Typography className="grpDetailKpiBox">Sold</Typography>
    //     //                                 </Grid>
    //     //                                 <Grid item>
    //     //                                     <div style={{ height: '90px', width: '90px', textAlign: 'center' }}>
    //     //                                         <CircularProgressbar
    //     //                                             value={state.data.soldHoursProgress}
    //     //                                             text={`${state.data.soldHoursProgress || 0}%`}
    //     //                                             styles={buildStyles({
    //     //                                                 textColor:
    //     //                                                     state.data.soldHoursProgress < 50
    //     //                                                         ? '#D92641'
    //     //                                                         : state.data.soldHoursProgress >= 50 &&
    //     //                                                           state.data.soldHoursProgress < state.data.soldHoursProgress
    //     //                                                         ? '#ff9f00'
    //     //                                                         : state.data.soldHoursProgress >= state.data.soldHoursProgress
    //     //                                                         ? '#569101'
    //     //                                                         : '#D92641',
    //     //                                                 pathColor:
    //     //                                                     state.data.soldHoursProgress < 50
    //     //                                                         ? '#D92641'
    //     //                                                         : state.data.soldHoursProgress >= 50 &&
    //     //                                                           state.data.soldHoursProgress < state.data.soldHoursProgress
    //     //                                                         ? '#ff9f00'
    //     //                                                         : state.data.soldHoursProgress >= state.data.soldHoursProgress
    //     //                                                         ? '#569101'
    //     //                                                         : '#D92641',
    //     //                                                 trailColor: '#d6d6d6',
    //     //                                                 textSize: '27px'
    //     //                                             })}
    //     //                                         />
    //     //                                     </div>
    //     //                                     <div>
    //     //                                         <Typography className="mainText">
    //     //                                             <span style={{ color: state.data?.soldHoursColour ? state.data?.soldHoursColour : 'black' }}>
    //     //                                                 {state.data.soldHours ? formatters.ThousandSeparatorWithoutZero(state.data?.soldHours) : 'N/A'}
    //     //                                             </span>
    //     //                                         </Typography>
    //     //                                         <span className="lableFont">Hours</span>
    //     //                                     </div>
    //     //                                 </Grid>
    //     //                                 <Grid item>
    //     //                                     <div style={{ height: '90px', width: '90px', textAlign: 'justify' }}>
    //     //                                         <CircularProgressbar
    //     //                                             value={state.data.soldAmountProgress}
    //     //                                             text={`${state.data.soldAmountProgress || 0}%`}
    //     //                                             styles={buildStyles({
    //     //                                                 textColor:
    //     //                                                     state.data.soldAmountProgress < 50
    //     //                                                         ? '#D92641'
    //     //                                                         : state.data.soldAmountProgress >= 50 &&
    //     //                                                           state.data.soldAmountProgress < state.data.soldAmountProgress
    //     //                                                         ? '#ff9f00'
    //     //                                                         : state.data.soldAmountProgress >= state.data.soldAmountProgress
    //     //                                                         ? '#569101'
    //     //                                                         : '#D92641',
    //     //                                                 pathColor:
    //     //                                                     state.data.soldAmountProgress < 50
    //     //                                                         ? '#D92641'
    //     //                                                         : state.data.soldAmountProgress >= 50 &&
    //     //                                                           state.data.soldAmountProgress < state.data.soldAmountProgress
    //     //                                                         ? '#ff9f00'
    //     //                                                         : state.data.soldAmountProgress >= state.data.soldAmountProgress
    //     //                                                         ? '#569101'
    //     //                                                         : '#D92641',
    //     //                                                 trailColor: '#d6d6d6',
    //     //                                                 textSize: '27px'
    //     //                                             })}
    //     //                                         />
    //     //                                     </div>
    //     //                                     <div>
    //     //                                         <Typography className="mainText">
    //     //                                             <span style={{ color: state.data?.soldAmountColour ? state.data?.soldAmountColour : 'black' }}>
    //     //                                                 {state.data.soldAmount
    //     //                                                     ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.soldAmount)
    //     //                                                     : 'N/A'}
    //     //                                             </span>
    //     //                                         </Typography>
    //     //                                         <span className="lableFont">Value</span>
    //     //                                     </div>
    //     //                                 </Grid>
    //     //                             </Grid>
    //     //                         </Grid>
    //     //                         <Grid item xs={12} sm={6} xl={2} style={{ cursor: 'pointer' }} onClick={() => handleRedirect()}>
    //     //                             <Grid container justifyContent="center" alignItems="center" spacing={2}>
    //     //                                 <Grid item xs={12}>
    //     //                                     <Typography className="grpDetailKpiBox">Labour</Typography>
    //     //                                 </Grid>
    //     //                                 <Grid item xs={12}>
    //     //                                     <div style={{ marginTop: 10 }}>
    //     //                                         <div className="mainText">
    //     //                                             <span style={{ color: 'black' }}>
    //     //                                                 {state.data.warrantyHours ? formatters.ThousandSeparatorWithoutZero(state.data?.warrantyHours) : 'N/A'}
    //     //                                             </span>{' '}
    //     //                                             /{' '}
    //     //                                             <span style={{ color: 'black' }}>
    //     //                                                 {state.data.warrantyAmount
    //     //                                                     ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.warrantyAmount)
    //     //                                                     : 'N/A'}
    //     //                                             </span>
    //     //                                         </div>

    //     //                                         <span className="secondaryHeading">
    //     //                                             Warranty <span style={{ fontSize: '12px', marginLeft: '5px' }}>(Hours/Value)</span>
    //     //                                         </span>
    //     //                                     </div>
    //     //                                 </Grid>
    //     //                                 <Grid item xs={12}>
    //     //                                     <div style={{ marginTop: 5 }}>
    //     //                                         <div className="mainText">
    //     //                                             <span style={{ color: 'black' }}>
    //     //                                                 {state.data.rmHours ? formatters.ThousandSeparatorWithoutZero(state.data?.rmHours) : 'N/A'}
    //     //                                             </span>{' '}
    //     //                                             /{' '}
    //     //                                             <span style={{ color: 'black' }}>
    //     //                                                 {state.data.rmAmount ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.rmAmount) : 'N/A'}
    //     //                                             </span>
    //     //                                         </div>
    //     //                                         <span className="secondaryHeading">
    //     //                                             {' '}
    //     //                                             R&M<span style={{ fontSize: '12px', marginLeft: '5px' }}>(Hours/Value)</span>
    //     //                                         </span>
    //     //                                     </div>
    //     //                                 </Grid>
    //     //                             </Grid>
    //     //                         </Grid>
    //     //                         <Grid item xs={12} sm={6} xl={3} style={{ cursor: 'pointer' }} onClick={() => handleRedirect()}>
    //     //                             <Grid container justifyContent="center" alignItems="center" spacing={2}>
    //     //                                 <Grid item xs={12}>
    //     //                                     <Typography className="grpDetailKpiBox">Parts</Typography>
    //     //                                 </Grid>
    //     //                                 {state.parts == null || state.parts.length <= 0 ? (
    //     //                                     <Grid container justifyContent="space-around" alignItems="center">
    //     //                                         No Records Found
    //     //                                     </Grid>
    //     //                                 ) : (
    //     //                                     state.parts.map((item) => {
    //     //                                         return (
    //     //                                             <Grid item xs={12}>
    //     //                                                 <div>
    //     //                                                     <div className="mainText">{formatters.CurrencyThousandSeparatorWithoutZero(item.partsAmount)}</div>
    //     //                                                     <span className="secondaryHeading">{item.partsDepartment}</span>
    //     //                                                 </div>
    //     //                                             </Grid>
    //     //                                         );
    //     //                                     })
    //     //                                 )}
    //     //                             </Grid>
    //     //                         </Grid>
    //     //                         <Grid item xs={12} sm={6} xl={2} style={{ cursor: 'pointer' }} onClick={() => handleRedirect()}>
    //     //                             <Grid container justifyContent="center" alignItems="center" alignContent="center" spacing={2}>
    //     //                                 <Grid item xs={12}>
    //     //                                     <Typography className="grpDetailKpiBox">WIP</Typography>
    //     //                                 </Grid>

    //     //                                 <Grid item xs={12}>
    //     //                                     <div>
    //     //                                         <div className="mainText">
    //     //                                             <span style={{ color: state.data?.wipHoursColour ? state.data?.wipHoursColour : 'black' }}>
    //     //                                                 {state.data.wipHours ? formatters.ThousandSeparatorWithoutZero(state.data?.wipHours) : 'N/A'}
    //     //                                             </span>{' '}
    //     //                                             /{' '}
    //     //                                             <span style={{ color: state.data?.wipAmountColour ? state.data?.wipAmountColour : 'black' }}>
    //     //                                                 {state.data.wipAmount ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.wipAmount) : 'N/A'}
    //     //                                             </span>
    //     //                                         </div>
    //     //                                     </div>
    //     //                                 </Grid>

    //     //                                 <Grid item xs={12}>
    //     //                                     <Typography className="grpDetailKpiBox">
    //     //                                         Surcharges<span style={{ fontSize: '12px', marginLeft: '5px' }}>(Quantity/Value)</span>
    //     //                                     </Typography>
    //     //                                 </Grid>

    //     //                                 <Grid item xs={12}>
    //     //                                     <div>
    //     //                                         <div className="mainText">
    //     //                                             {' '}
    //     //                                             <span style={{ color: 'black' }}>
    //     //                                                 {state.data.surchargeQuantity
    //     //                                                     ? formatters.ThousandSeparatorWithoutZero(state.data?.surchargeQuantity)
    //     //                                                     : 'N/A'}
    //     //                                             </span>{' '}
    //     //                                             /{' '}
    //     //                                             <span style={{ color: 'black' }}>
    //     //                                                 {state.data.surchargeAmount
    //     //                                                     ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.surchargeAmount)
    //     //                                                     : 'N/A'}
    //     //                                             </span>
    //     //                                         </div>
    //     //                                     </div>
    //     //                                 </Grid>
    //     //                             </Grid>
    //     //                         </Grid>
    //     //                         <Grid item xs={12} sm={6} xl={3} style={{ cursor: 'pointer' }} onClick={() => handleRedirect()}>
    //     //                             <Grid container justifyContent="center" alignItems="center" alignContent="center" spacing={2}>
    //     //                                 <Grid item xs={12}>
    //     //                                     <Typography className="grpDetailKpiBox">Customer throughput</Typography>
    //     //                                 </Grid>

    //     //                                 <Grid item xs={12}>
    //     //                                     <div>
    //     //                                         <div className="mainText">
    //     //                                             <span style={{ color: 'black' }}>
    //     //                                                 {state.data.uniqueCustomers
    //     //                                                     ? formatters.ThousandSeparatorWithoutZero(state.data?.uniqueCustomers)
    //     //                                                     : 'N/A'}
    //     //                                             </span>
    //     //                                         </div>
    //     //                                     </div>
    //     //                                 </Grid>
    //     //                                 <Grid item xs={12}>
    //     //                                     <Typography className="grpDetailKpiBox">No. of Technicians</Typography>
    //     //                                 </Grid>

    //     //                                 <Grid item xs={12}>
    //     //                                     <div>
    //     //                                         <div className="mainText">
    //     //                                             <span style={{ color: 'black' }}>
    //     //                                                 {state.data.technicianBudget
    //     //                                                     ? formatters.ThousandSeparatorWithoutZero(state.data?.technicianBudget)
    //     //                                                     : 'N/A'}
    //     //                                             </span>{' '}
    //     //                                             /{' '}
    //     //                                             <span style={{ color: state.data?.technicianBudget > state.data?.technicianWorking ? 'red' : 'green' }}>
    //     //                                                 {state.data.technicianWorking
    //     //                                                     ? formatters.ThousandSeparatorWithoutZero(state.data?.technicianWorking)
    //     //                                                     : 'N/A'}
    //     //                                             </span>
    //     //                                         </div>
    //     //                                     </div>
    //     //                                 </Grid>
    //     //                             </Grid>
    //     //                         </Grid>
    //     //                     </Grid>
    //     //                 </Grid>
    //     //             </Grid>

    //     //             <br />
    //     //             <br />

    //     //             <Grid container spacing={2}>
    //     //                 <Grid item xs={12}>
    //     //                     <Typography variant="h5" color="secondary" style={{ textAlign: 'left', fontWeight: 700 }}>
    //     //                         Vehicle Sales
    //     //                     </Typography>
    //     //                     {/* <hr style={{ color: 'black' }} /> */}
    //     //                 </Grid>
    //     //                 <Grid item xs={12} className="mainContainer">
    //     //                     <Grid container spacing={1}>
    //     //                         <Grid item xs={12} sm={6} xl={4}>
    //     //                             <Grid container justifyContent="space-around" alignItems="center" spacing={2}>
    //     //                                 <Grid item xs={12}>
    //     //                                     <Typography className="grpDetailKpiBox alignLeft ml-10">VAN REGISTRATIONS / PROFIT</Typography>
    //     //                                 </Grid>

    //     //                                 <VehicleCard />
    //     //                             </Grid>
    //     //                         </Grid>
    //     //                         <Grid item xs={12} sm={6} xl={4}>
    //     //                             <Grid container justifyContent="center" alignItems="center" spacing={2}>
    //     //                                 <Grid item xs={12}>
    //     //                                     <Typography className="grpDetailKpiBox alignLeft ml-10">USED INVOICING</Typography>
    //     //                                 </Grid>
    //     //                                 <VehicleCard />
    //     //                             </Grid>
    //     //                         </Grid>

    //     //                         <Grid item xs={12} sm={6} xl={4}>
    //     //                             <Grid container justifyContent="center" alignItems="center" spacing={2}>
    //     //                                 <Grid item xs={12}>
    //     //                                     <Typography className="grpDetailKpiBox alignLeft ml-10">VAN ORDER INTAKE</Typography>
    //     //                                 </Grid>
    //     //                                 <Grid item xs={12}>
    //     //                                     <Typography className="mainText alignLeft ml-10">
    //     //                                         {formatters.CurrencyThousandSeparatorWithoutZero(state.data?.surchargeAmount)}
    //     //                                     </Typography>
    //     //                                 </Grid>
    //     //                                 <Grid item xs={12}>
    //     //                                     <Typography className="grpDetailKpiBox alignLeft ml-10">TRUCK ORDER INTAKE</Typography>
    //     //                                 </Grid>
    //     //                                 <Grid item xs={12}>
    //     //                                     <Typography className="mainText alignLeft ml-10">
    //     //                                         {formatters.CurrencyThousandSeparatorWithoutZero(state.data?.surchargeAmount)}
    //     //                                     </Typography>
    //     //                                 </Grid>
    //     //                             </Grid>
    //     //                         </Grid>

    //     //                         <Grid item xs={12} sm={6} xl={4}>
    //     //                             <Grid container justifyContent="center" alignItems="center" spacing={2}>
    //     //                                 <Grid item xs={12}>
    //     //                                     <Typography className="grpDetailKpiBox alignLeft ml-10">TRUCK REGISTRATIONS / PROFIT</Typography>
    //     //                                 </Grid>
    //     //                                 <VehicleCard />
    //     //                             </Grid>
    //     //                         </Grid>

    //     //                         <Grid item xs={12} sm={6} xl={4}>
    //     //                             <Grid container justifyContent="center" alignItems="center" spacing={2}>
    //     //                                 <Grid item xs={12}>
    //     //                                     <Typography className="grpDetailKpiBox alignLeft ml-10">EV TARGET</Typography>
    //     //                                 </Grid>
    //     //                                 <VehicleCard />
    //     //                             </Grid>
    //     //                         </Grid>
    //     //                     </Grid>

    //     //                     <Grid container spacing={1}></Grid>
    //     //                 </Grid>
    //     //             </Grid>
    //     //         </Grid>
    //     //     )}
    //     // </Grid>

    // );
    return (
        <Grid
            container
            style={{
                // padding: '10px 0px',
                height: 'calc(100vh - 105px)'
            }}
            justifyContent="center"
            className="salesBudgetScreen"
            alignItems="center"
        >
            {state.isLoader ? (
                <CircularProgress color="secondary" />
            ) : (
                <Grid item xs={12} sm={10} md={11} lg={11} xl={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Grid container className="mainContainer" style={{ cursor: 'pointer' }} onClick={() => handleRedirect()}>
                                        <Grid item xs={12}>
                                            <Typography className="grpDetailKpiBox">Sold</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container justifyContent="space-around">
                                                <Grid item>
                                                    <div style={{ height: '90px', width: '90px', textAlign: 'center' }}>
                                                        <CircularProgressbar
                                                            value={state.data.soldHoursProgress}
                                                            text={`${state.data.soldHoursProgress || 0}%`}
                                                            styles={buildStyles({
                                                                textColor:
                                                                    state.data.soldHoursProgress < 50
                                                                        ? '#D92641'
                                                                        : state.data.soldHoursProgress >= 50 &&
                                                                          state.data.soldHoursProgress < state.data.soldHoursProgress
                                                                        ? '#ff9f00'
                                                                        : state.data.soldHoursProgress >= state.data.soldHoursProgress
                                                                        ? '#569101'
                                                                        : '#D92641',
                                                                pathColor:
                                                                    state.data.soldHoursProgress < 50
                                                                        ? '#D92641'
                                                                        : state.data.soldHoursProgress >= 50 &&
                                                                          state.data.soldHoursProgress < state.data.soldHoursProgress
                                                                        ? '#ff9f00'
                                                                        : state.data.soldHoursProgress >= state.data.soldHoursProgress
                                                                        ? '#569101'
                                                                        : '#D92641',
                                                                trailColor: '#d6d6d6',
                                                                textSize: '27px'
                                                            })}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Typography style={{ fontSize: 25, fontWeight: '600', cursor: 'pointer' }}>
                                                            <span style={{ color: state.data?.soldHoursColour ? state.data?.soldHoursColour : 'black' }}>
                                                                {state.data.soldHours ? formatters.ThousandSeparatorWithoutZero(state.data?.soldHours) : 'N/A'}
                                                            </span>
                                                        </Typography>
                                                        <span>Hours</span>
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div style={{ height: '90px', width: '90px', textAlign: 'justify' }}>
                                                        <CircularProgressbar
                                                            value={state.data.soldAmountProgress}
                                                            text={`${state.data.soldAmountProgress || 0}%`}
                                                            styles={buildStyles({
                                                                textColor:
                                                                    state.data.soldAmountProgress < 50
                                                                        ? '#D92641'
                                                                        : state.data.soldAmountProgress >= 50 &&
                                                                          state.data.soldAmountProgress < state.data.soldAmountProgress
                                                                        ? '#ff9f00'
                                                                        : state.data.soldAmountProgress >= state.data.soldAmountProgress
                                                                        ? '#569101'
                                                                        : '#D92641',
                                                                pathColor:
                                                                    state.data.soldAmountProgress < 50
                                                                        ? '#D92641'
                                                                        : state.data.soldAmountProgress >= 50 &&
                                                                          state.data.soldAmountProgress < state.data.soldAmountProgress
                                                                        ? '#ff9f00'
                                                                        : state.data.soldAmountProgress >= state.data.soldAmountProgress
                                                                        ? '#569101'
                                                                        : '#D92641',
                                                                trailColor: '#d6d6d6',
                                                                textSize: '27px'
                                                            })}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Typography style={{ fontSize: 25, fontWeight: '600', cursor: 'pointer' }}>
                                                            <span style={{ color: state.data?.soldAmountColour ? state.data?.soldAmountColour : 'black' }}>
                                                                {state.data.soldAmount
                                                                    ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.soldAmount)
                                                                    : 'N/A'}
                                                            </span>
                                                        </Typography>
                                                        <span>Value</span>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Grid container className="mainContainer" style={{ cursor: 'pointer' }} onClick={() => handleRedirect()}>
                                        <Grid item xs={12}>
                                            <Typography className="grpDetailKpiBox">WIP</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container justifyContent="space-around">
                                                <Grid item>
                                                    <div style={{ height: '90px', width: '90px', textAlign: 'center' }}>
                                                        <CircularProgressbar
                                                            value={state.data.wipHoursProgress}
                                                            text={`${state.data.wipHoursProgress || 0}%`}
                                                            styles={buildStyles({
                                                                textColor:
                                                                    state.data.wipHoursProgress < 50
                                                                        ? '#D92641'
                                                                        : state.data.wipHoursProgress >= 50 &&
                                                                          state.data.wipHoursProgress < state.data.wipHoursProgress
                                                                        ? '#ff9f00'
                                                                        : state.data.wipHoursProgress >= state.data.wipHoursProgress
                                                                        ? '#569101'
                                                                        : '#D92641',
                                                                pathColor:
                                                                    state.data.wipHoursProgress < 50
                                                                        ? '#D92641'
                                                                        : state.data.wipHoursProgress >= 50 &&
                                                                          state.data.wipHoursProgress < state.data.wipHoursProgress
                                                                        ? '#ff9f00'
                                                                        : state.data.wipHoursProgress >= state.data.wipHoursProgress
                                                                        ? '#569101'
                                                                        : '#D92641',
                                                                trailColor: '#d6d6d6',
                                                                textSize: '27px'
                                                            })}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Typography style={{ fontSize: 25, fontWeight: '600', cursor: 'pointer' }}>
                                                            <span style={{ color: state.data?.wipHoursColour ? state.data?.wipHoursColour : 'black' }}>
                                                                {state.data.wipHours ? formatters.ThousandSeparatorWithoutZero(state.data?.wipHours) : 'N/A'}
                                                            </span>
                                                        </Typography>
                                                        <span>Hours</span>
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <div style={{ height: '90px', width: '90px', textAlign: 'justify' }}>
                                                        <CircularProgressbar
                                                            value={state.data.wipHoursAmountProgress}
                                                            text={`${state.data.wipHoursAmountProgress || 0}%`}
                                                            styles={buildStyles({
                                                                textColor:
                                                                    state.data.wipHoursAmountProgress < 50
                                                                        ? '#D92641'
                                                                        : state.data.wipHoursAmountProgress >= 50 &&
                                                                          state.data.wipHoursAmountProgress < state.data.wipHoursAmountProgress
                                                                        ? '#ff9f00'
                                                                        : state.data.wipHoursAmountProgress >= state.data.wipHoursAmountProgress
                                                                        ? '#569101'
                                                                        : '#D92641',
                                                                pathColor:
                                                                    state.data.wipHoursAmountProgress < 50
                                                                        ? '#D92641'
                                                                        : state.data.wipHoursAmountProgress >= 50 &&
                                                                          state.data.wipHoursAmountProgress < state.data.wipHoursAmountProgress
                                                                        ? '#ff9f00'
                                                                        : state.data.wipHoursAmountProgress >= state.data.wipHoursAmountProgress
                                                                        ? '#569101'
                                                                        : '#D92641',
                                                                trailColor: '#d6d6d6',
                                                                textSize: '27px'
                                                            })}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Typography style={{ fontSize: 25, fontWeight: '600', cursor: 'pointer' }}>
                                                            <span style={{ color: state.data?.wipAmountColour ? state.data?.wipAmountColour : 'black' }}>
                                                                {state.data.wipAmount
                                                                    ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.wipAmount)
                                                                    : 'N/A'}
                                                            </span>
                                                        </Typography>
                                                        <span>Value</span>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid> */}

                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Grid container className="mainContainer" style={{ cursor: 'pointer' }} onClick={() => handleRedirect()}>
                                        <Grid item xs={12}>
                                            <Typography className="grpDetailKpiBox">WIP</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography style={{ fontSize: 25, fontWeight: '600', cursor: 'pointer' }}>
                                                <span style={{ color: state.data?.wipHoursColour ? state.data?.wipHoursColour : 'black' }}>
                                                    {state.data.wipHours ? formatters.ThousandSeparatorWithoutZero(state.data?.wipHours) : 'N/A'}
                                                </span>{' '}
                                                /{' '}
                                                <span style={{ color: state.data?.wipAmountColour ? state.data?.wipAmountColour : 'black' }}>
                                                    {state.data.wipAmount ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.wipAmount) : 'N/A'}
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Grid container className="mainContainer" style={{ cursor: 'pointer' }} onClick={() => handleRedirect()}>
                                        <Grid item xs={12}>
                                            <Typography className="grpDetailKpiBox">No. of Technicians</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography style={{ fontSize: 25, fontWeight: '600', cursor: 'pointer' }}>
                                                <span style={{ color: 'black' }}>
                                                    {state.data.technicianBudget
                                                        ? formatters.ThousandSeparatorWithoutZero(state.data?.technicianBudget)
                                                        : 'N/A'}
                                                </span>{' '}
                                                /{' '}
                                                <span style={{ color: state.data?.technicianBudget > state.data?.technicianWorking ? 'red' : 'green' }}>
                                                    {state.data.technicianWorking
                                                        ? formatters.ThousandSeparatorWithoutZero(state.data?.technicianWorking)
                                                        : 'N/A'}
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* //// */}
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Grid container className="mainContainer" style={{ cursor: 'pointer' }} onClick={() => handleRedirect()}>
                                        <Grid item xs={12}>
                                            <Typography className="grpDetailKpiBox">Customer throughput MTD</Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography style={{ fontSize: 25, fontWeight: '600', cursor: 'pointer' }} onClick={() => handleRedirect()}>
                                                <span style={{ color: 'black' }}>
                                                    {state.data.uniqueCustomers ? formatters.ThousandSeparatorWithoutZero(state.data?.uniqueCustomers) : 'N/A'}
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} md={8} lg={8}>
                                    <Grid
                                        container
                                        justifyContent="space-around"
                                        className="mainContainer"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleRedirect()}
                                    >
                                        <Grid item xs={12}>
                                            <Typography variant="h6" style={{ borderRadius: '4px', textAlign: 'left', paddingLeft: '5px' }}>
                                                {/* Parts */}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={4} lg={3}>
                                            <Typography
                                                className="grpDetailKpiBox"
                                                style={{
                                                    fontSize: 20,
                                                    cursor: 'pointer',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                Warranty
                                                <span style={{ fontSize: '12px', marginLeft: '5px' }}>(Hours/Value)</span>
                                            </Typography>

                                            <Typography
                                                style={{ fontSize: 14, fontWeight: '600', cursor: 'pointer', textAlign: 'left' }}
                                                // onClick={() => GoToDailyInvoicedHours({ startDateType: 'mtd' })}
                                            >
                                                <span>
                                                    <Typography
                                                        style={{
                                                            fontSize: 25,
                                                            fontWeight: '600',
                                                            cursor: 'pointer',
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        <span style={{ color: 'black' }}>
                                                            {state.data.warrantyHours
                                                                ? formatters.ThousandSeparatorWithoutZero(state.data?.warrantyHours)
                                                                : 'N/A'}
                                                        </span>{' '}
                                                        /{' '}
                                                        <span style={{ color: 'black' }}>
                                                            {state.data.warrantyAmount
                                                                ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.warrantyAmount)
                                                                : 'N/A'}
                                                        </span>
                                                    </Typography>
                                                </span>
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={4} lg={3}>
                                            <Typography
                                                className="grpDetailKpiBox"
                                                style={{
                                                    fontSize: 20,
                                                    cursor: 'pointer',
                                                    textAlign: 'left'
                                                }}
                                            >
                                                R&M<span style={{ fontSize: '12px', marginLeft: '5px' }}>(Hours/Value)</span>
                                            </Typography>

                                            <Typography style={{ fontSize: 14, fontWeight: '600', cursor: 'pointer', textAlign: 'left' }}>
                                                <span>
                                                    <Typography
                                                        style={{
                                                            fontSize: 25,
                                                            fontWeight: '600',
                                                            cursor: 'pointer',
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        <span style={{ color: 'black' }}>
                                                            {state.data.rmHours ? formatters.ThousandSeparatorWithoutZero(state.data?.rmHours) : 'N/A'}
                                                        </span>{' '}
                                                        /{' '}
                                                        <span style={{ color: 'black' }}>
                                                            {state.data.rmAmount
                                                                ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.rmAmount)
                                                                : 'N/A'}
                                                        </span>
                                                    </Typography>
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* /// */}
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Grid container className="mainContainer" style={{ cursor: 'pointer' }} onClick={() => handleRedirect()}>
                                        <Grid item xs={12}>
                                            <Typography className="grpDetailKpiBox">
                                                Surcharges<span style={{ fontSize: '12px', marginLeft: '5px' }}>(Quantity/Value)</span>
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography style={{ fontSize: 25, fontWeight: '600', cursor: 'pointer' }}>
                                                <span style={{ color: 'black' }}>
                                                    {state.data.surchargeQuantity
                                                        ? formatters.ThousandSeparatorWithoutZero(state.data?.surchargeQuantity)
                                                        : 'N/A'}
                                                </span>{' '}
                                                /{' '}
                                                <span style={{ color: 'black' }}>
                                                    {state.data.surchargeAmount
                                                        ? formatters.CurrencyThousandSeparatorWithoutZero(state.data?.surchargeAmount)
                                                        : 'N/A'}
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8} lg={8}>
                                    <Grid container className="mainContainer" style={{ cursor: 'pointer' }} onClick={() => handleRedirect()}>
                                        <Grid item xs={12}>
                                            <Typography className="grpDetailKpiBox">Parts</Typography>
                                        </Grid>
                                        {state.parts == null || state.parts.length <= 0 ? (
                                            <Grid container justifyContent="space-around">
                                                No Records Found
                                            </Grid>
                                        ) : (
                                            <Grid container justifyContent="space-around">
                                                {state.parts.map((item) => {
                                                    return (
                                                        <Grid item xs={12} sm={4} lg={3}>
                                                            <Typography
                                                                style={{
                                                                    fontSize: 20,
                                                                    fontWeight: '600',
                                                                    cursor: 'pointer',
                                                                    textAlign: 'left'
                                                                }}
                                                            >
                                                                {item.partsDepartment}
                                                            </Typography>

                                                            <Typography style={{ fontSize: 14, fontWeight: '600', cursor: 'pointer', textAlign: 'left' }}>
                                                                <span style={{ color: item.mtdBudget > item.partsAmount ? 'red' : 'green' }}>
                                                                    <Typography
                                                                        style={{
                                                                            fontSize: 20,
                                                                            fontWeight: '600',
                                                                            cursor: 'pointer',
                                                                            textAlign: 'left'
                                                                        }}
                                                                    >
                                                                        {formatters.CurrencyThousandSeparatorWithoutZero(item.partsAmount)}
                                                                    </Typography>
                                                                </span>
                                                            </Typography>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default MiniViewReportingDashboard;
