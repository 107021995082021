import axios from 'axios';
import { AppStorage } from './storage-service';

let config = window._ENV_;
let _server = '';
if (process.env.NODE_ENV === 'production') {
    // _server = 'https://ewipapi.synergiesoftware.com';
    //_server = 'https://ewiptestapi.sparshattgroup.com';
    //_server = 'https://ewipapi.northside.co.uk';
    _server = config.backendUrl.prod;
} else {
    _server = 'https://localhost:44367';
    // _server = 'https://ewipapi.synergiesoftware.com';
    _server = config.backendUrl.dev;
}
const defaultOptions = {
    baseURL: `${_server}`,
    headers: {
        'Content-Type': 'application/json'
    },
    validateStatus: null
};

let instance = axios.create(defaultOptions);

instance.interceptors.request.use(function (config) {
    if (config.useAuthToken) {
        const token = AppStorage.getToken();
        config.headers.Authorization = token ? `Bearer ${token}` : '';
    }
    config.headers.domainName = window.location.host.includes('localhost') ? 'Default' : window.location.host;
    return config;
});

const generateRoutingURL = (url) => {
    if (/^\w/.test(url)) {
        return `api/${url}`;
    }
    return url;
};

function HttpVerbEmitter() {
    this.callBacks = [];
    this.addEventListener = (cb) => {
        this.callBacks.push(cb);
    };
    this.raise = (e) => this.callBacks.forEach((cb) => cb(e));
}

const httpVerbEmitter = new HttpVerbEmitter();

const logoutHandle = () => {
    console.log('Log out trigger from http-call.js passed trigger-logout to app.jsx');
    setTimeout(() => {
        window.top.postMessage({ type: 'trigger-logout' }, '*');
    }, 1000);
};

export { httpVerbEmitter };

export let put = async (url, data, options = {}) => {
    let res = await instance.put(generateRoutingURL(url), data, options);
    if (res.status === 401) {
        logoutHandle();
    }
    if (!res.data.success) {
        console.warn(`Request fail. Status Code:(${res.status}), Status Text:(${res.statusText})`);
    }
    return res.data;
};

export let post = async (url, data, options = {}) => {
    let res = await instance.post(generateRoutingURL(url), data, options);
    if (res.status === 401) {
        logoutHandle();
    }
    if (!res.data.success) {
        console.warn(`Request fail. Status Code:(${res.status}), Status Text:(${res.statusText})`);
    }
    return res.data;
};

export let get = async (url, options = {}) => {
    //httpVerbEmitter.raise({t:'T'});
    let res = await instance.get(generateRoutingURL(url), options);
    if (res.status === 401) {
        logoutHandle();
    }
    if (!res.data.success) {
        console.warn(`Request fail. Status Code:(${res.status}), Status Text:(${res.statusText})`);
    }
    return options.returnOrgRes ? res : res.data;
};
export let ExpBaseURL = `${_server}`;
