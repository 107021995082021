import React, { useContext, useEffect, useState } from 'react';
import { Container, Grid, Avatar, Typography, Button, ButtonGroup, CircularProgress } from '@material-ui/core';
import SpeedIcon from '@material-ui/icons/Speed';
import { useHistory } from 'react-router-dom';
import { getReprtingAfterSaleDashboard } from '../../../Core/Service/ReportScreens-service';
import './ReportingScreensStyle.scss';
import {
    AfterSaleBranchCard,
    AfterSaleBranchCardPart,
    AfterSaleCombined,
    AfterSaleJobsDetails,
    AfterSaleSupercharge,
    AfterSaleYtdMtdSection
} from './saleDashboardCard';
import { AppStorage } from '../../../Core/Service/storage-service';
import moment from 'moment';
import AppContext from '../../../App/AppContext';
// import { Typography  from '@material-ui/core/Typography';
function isFiniteCheck(str) {
    return isFinite(str) ? str.toFixed(2) : '0.00';
}

const AfterSaleReportingDashboard = () => {
    const { showToast, showModal } = useContext(AppContext);

    const history = useHistory();
    const portalSettings = AppStorage.getPortalSetting();

    const isCommunicatorAllowed = AppStorage.getCommunicatorAllowed() && portalSettings.portalSettingCommunicatorEnabled;
    const RedirectToDash = (val) => {
        history.push({
            pathname: val
        });
    };

    const [state, setState] = useState({
        TotalSold: 0,
        TotalTaken: 0,
        TotalSale: 0,
        TotalCost: 0,
        YTDTotalSold: 0,
        YTDTotalTaken: 0,
        YTDTotalSale: 0,
        YTDTotalCost: 0,
        soldHoursArr: [],
        partsArr: [],
        mtdGrossProfit: 0,
        ytdGrossProfit: 0,
        mtdSaleValueCountP: 0,
        ytdSaleValueCountP: 0,
        mtdSaleValueCountWC: 0,
        ytdSaleValueCountWC: 0,
        showLoader: true,
        isRecordAvalaible: false,
        isHoursSoldBudgetData: false
        // soldHoursArr: [],
    });

    const [partsData, setPartsData] = useState({
        partsCalculatedList: [],
        partsArr: [],
        uniqueBranchList: [],
        graphData: [],
        showLoader: true,
        isPartData: false
    });

    const [surchargesData, setSurchargesData] = useState({ surchargesArr: [] });
    const [ytdMtdData, setYtdMtdData] = useState({ monthlyWIPsUniqueDateList: [], monthlyWIPsCombinedList: [], isSurchargesData: false });

    useEffect(async () => {
        let res = await getReprtingAfterSaleDashboard();

        if (res.success) {
            let hoursSoldBudgetList = res.data?.hoursSoldBudgetList || [];
            let partsVOR = res.data?.partsVOR[0];
            let parts = res.data?.parts || [];
            let surcharges = res.data?.surcharges || [];
            let monthlyWIPs = res.data?.monthlyWIPs || [];
            let dailyWIPs = res.data?.dailyWIPs || [];

            if (monthlyWIPs.length > 0) {
                let monthlyWIPsCombinedList = [];
                let monthlyWIPsUniqueDateList = [];

                monthlyWIPs.forEach((p) => {
                    monthlyWIPsUniqueDateList.push({ date: p.date, name: moment(p.date).format('MMM-YY'), Total: 0 });
                    let objIndex = monthlyWIPsCombinedList.findIndex((q) => q.branchID === p.branchID);

                    if (objIndex > -1) {
                        monthlyWIPsCombinedList[objIndex].data.push({ ...p });
                    } else {
                        monthlyWIPsCombinedList.push({
                            branchID: p.branchID,
                            branchShortCode: p.branchShortCode,
                            data: [{ ...p }]
                        });
                    }
                });
                monthlyWIPsUniqueDateList = [...new Map(monthlyWIPsUniqueDateList.map((item) => [item['date'], item])).values()];
                monthlyWIPs.forEach((p) => {
                    let objIndex = monthlyWIPsUniqueDateList.findIndex((q) => q.date === p.date);

                    if (objIndex > -1) {
                        monthlyWIPsUniqueDateList[objIndex].Total += p.hours;
                    }
                });

                let monthlyWIPsUniqueBranchList = [];
                let monthlyWIPsGrapgData = [];

                [...monthlyWIPs].forEach((q) => {
                    monthlyWIPsUniqueBranchList.push(q.branchShortCode);

                    let isIDExist = monthlyWIPsGrapgData.findIndex((t) => t.date == moment(q.date).format('MMM-YY'));
                    if (isIDExist > -1) {
                        monthlyWIPsGrapgData[isIDExist][q.branchShortCode] = q.hours;
                        monthlyWIPsGrapgData[isIDExist].Total += q.hours;
                    } else {
                        monthlyWIPsGrapgData.push({
                            date: moment(q.date).format('MMM-YY'),
                            [q.branchShortCode]: q.hours,
                            Total: 0 + +q.hours
                        });
                    }
                });
                monthlyWIPsUniqueBranchList = [...new Set(monthlyWIPsUniqueBranchList)];
                monthlyWIPsUniqueBranchList.push('Total');
                /////////

                let dailyWIPsCombinedList = [];
                let dailyWIPsUniqueDateList = [];

                [...dailyWIPs].forEach((p) => {
                    dailyWIPsUniqueDateList.push({ date: p.date, name: moment(p.date).format('DD/MM'), Total: 0 });
                    let objIndex = dailyWIPsCombinedList.findIndex((q) => q.branchID === p.branchID);
                    if (objIndex > -1) {
                        dailyWIPsCombinedList[objIndex].data.push({ ...p });
                    } else {
                        dailyWIPsCombinedList.push({
                            branchID: p.branchID,
                            branchShortCode: p.branchShortCode,
                            data: [{ ...p }]
                        });
                    }
                });
                dailyWIPsUniqueDateList = [...new Map(dailyWIPsUniqueDateList.map((item) => [item['date'], item])).values()];

                dailyWIPs.forEach((p) => {
                    let objIndex = dailyWIPsUniqueDateList.findIndex((q) => q.date === p.date);

                    if (objIndex > -1) {
                        dailyWIPsUniqueDateList[objIndex].Total += p.hours;
                    }
                });
                let dailyWIPsUniqueBranchList = [];
                let dailyWIPsGrapgData = [];

                [...dailyWIPs].forEach((q) => {
                    dailyWIPsUniqueBranchList.push(q.branchShortCode);

                    let isIDExist = dailyWIPsGrapgData.findIndex((t) => t.date == moment(q.date).format('DD/MM/YYYY'));
                    if (isIDExist > -1) {
                        dailyWIPsGrapgData[isIDExist][q.branchShortCode] = q.hours;
                        // dailyWIPsGrapgData[isIDExist].Total += q.hours;
                    } else {
                        dailyWIPsGrapgData.push({
                            date: moment(q.date).format('DD/MM/YYYY'),
                            [q.branchShortCode]: q.hours
                            // Total: 0 + +q.hours
                        });
                    }
                });
                dailyWIPsUniqueBranchList = [...new Set(dailyWIPsUniqueBranchList)];
                // dailyWIPsUniqueBranchList.push('Total');

                setYtdMtdData((pt) => ({
                    ...pt,
                    monthlyWIPsUniqueDateList: monthlyWIPsUniqueDateList || [],
                    monthlyWIPsCombinedList: monthlyWIPsCombinedList || [],
                    dailyWIPsCombinedList: dailyWIPsCombinedList || [],
                    dailyWIPsUniqueDateList: dailyWIPsUniqueDateList || [],
                    monthlyWIPsGrapgData: monthlyWIPsGrapgData || [],
                    monthlyWIPsUniqueBranchList: monthlyWIPsUniqueBranchList || [],
                    dailyWIPsGrapgData: dailyWIPsGrapgData || [],
                    dailyWIPsUniqueBranchList: dailyWIPsUniqueBranchList || []
                }));
            }

            if (hoursSoldBudgetList.length > 0) {
                let TotalSold = 0;
                let TotalTaken = 0;
                let TotalSale = 0;
                let TotalCost = 0;
                let YTDTotalSold = 0;
                let YTDTotalTaken = 0;
                let YTDTotalSale = 0;
                let YTDTotalCost = 0;
                let MTDTotalBudget = 0;
                let YTDTotalBudget = 0;
                let mtdBudget = 0;
                let ytdBudget = 0;
                let totalCount = [...hoursSoldBudgetList].forEach(function (item) {
                    TotalSold += item.mtdSold;
                    TotalTaken += item.mtdTaken;
                    TotalSale += item.mtdSale;
                    TotalCost += item.mtdCost;
                    YTDTotalSold += item.ytdSold;
                    YTDTotalTaken += item.ytdTaken;
                    YTDTotalSale += item.ytdSale;
                    YTDTotalCost += item.ytdCost;
                    MTDTotalBudget += item.mtdHoursBudget;
                    YTDTotalBudget += item.ytdHoursBudget;
                    mtdBudget += item.mtdBudget;
                    ytdBudget += item.ytdBudget;
                    // graphYAxis += item.mtdSold -item.mtdBudget;
                });

                var soldHoursArr = hoursSoldBudgetList.reduce((acc, obj) => {
                    var index = acc.find((item) => item.bn === obj.bn);
                    if (index) {
                        index.mtdSold += obj.mtdSold;
                        index.mtdTaken += obj.mtdTaken;
                        index.mtdSale += obj.mtdSale;
                        index.mtdCost += obj.mtdCost;
                        index.ytdSold += obj.ytdSold;
                        index.ytdTaken += obj.ytdTaken;
                        index.ytdSale += obj.ytdSale;
                        index.ytdCost += obj.ytdCost;

                        return acc;
                    }
                    acc.push({ ...obj });
                    return acc;
                }, []);

                soldHoursArr = soldHoursArr.map((p, i) => {
                    let recRateTable = p.mtdSale / p.mtdSold;
                    let cal = p.mtdSold - p.mtdHoursBudget;
                    let invPound = p.mtdSale - p.mtdBudget;
                    let rec = p.mtdSale / p.mtdSold - p.mtdRecoveryRateBudget;
                    let eff = (p.mtdSold / p.mtdTaken) * 100 - p.mtdEfficiencyBudget;
                    let monthInvoicingPoundDiff = p.mtdSale - p.monthBudget;
                    let monthInvoicingPoundPercentage = (p.mtdSale / p.monthBudget) * 100 > 100 ? 100 : (p.mtdSale / p.monthBudget) * 100;
                    let monthHoursBudgetDiff = p.mtdSold - p.monthHoursBudget;
                    let monthHoursBudgetPercentage = (p.mtdSold / p.monthHoursBudget) * 100 > 100 ? 100 : (p.mtdSold / p.monthHoursBudget) * 100;
                    return {
                        ...p,
                        recRateTable: recRateTable,
                        invoicingHours: Math.round(cal),
                        RecoveryRate: rec,
                        invoicingPound: Math.round(invPound),
                        efficiencyBud: Math.round(eff),
                        monthHoursBudgetPercentage: monthHoursBudgetPercentage,
                        monthHoursBudgetDiff: Math.round(monthHoursBudgetDiff),
                        monthInvoicingPoundPercentage: monthInvoicingPoundPercentage,
                        monthInvoicingPoundDiff: Math.round(monthInvoicingPoundDiff)
                    };
                });

                setState((st) => ({
                    ...st,
                    TotalSold: TotalSold,
                    TotalTaken: TotalTaken,
                    TotalSale: TotalSale,
                    TotalCost: TotalCost,
                    YTDTotalSold: YTDTotalSold,
                    YTDTotalTaken: YTDTotalTaken,
                    YTDTotalSale: YTDTotalSale,
                    YTDTotalCost: YTDTotalCost,
                    soldHoursArr: soldHoursArr,
                    MTDTotalBudget: MTDTotalBudget,
                    YTDTotalBudget: YTDTotalBudget,
                    mtdBudget: mtdBudget,
                    ytdBudget: ytdBudget,
                    partsVOR: partsVOR,
                    isHoursSoldBudgetData: hoursSoldBudgetList.length > 0 ? true : false
                }));
            }
            if (parts.length > 0) {
                let partsCalculatedList = parts.reduce((acc, obj) => {
                    var index = acc.find((item) => item.dc === obj.dc);
                    if (index) {
                        index.mtdSaleValue += +obj.mtdSaleValue;
                        index.ytdSaleValue += +obj.ytdSaleValue;
                        index.mtdGrossProfit += obj.mtdGrossProfit;
                        index.ytdGrossProfit += obj.ytdGrossProfit;
                        index.mtdBudget += obj.mtdBudget;
                        index.ytdBudget += obj.ytdBudget;
                        index.mtdgpp += obj.mtdgpp;
                        index.ytdgpp += obj.ytdgpp;
                        return acc;
                    }
                    acc.push({ ...obj });
                    return acc;
                }, []);

                let pratRowCount = parts.length;
                let totalCountObj = {
                    dc: '',
                    d: '',
                    mtdSaleValue: 0,
                    ytdSaleValue: 0,
                    mtdGrossProfit: 0,
                    ytdGrossProfit: 0,
                    mtdBudget: 0,
                    ytdBudget: 0,
                    mtdgpp: 0,
                    ytdgpp: 0
                };

                partsCalculatedList.forEach((p) => {
                    totalCountObj.mtdSaleValue += p.mtdSaleValue;
                    totalCountObj.ytdSaleValue += p.ytdSaleValue;
                    totalCountObj.mtdGrossProfit += p.mtdGrossProfit;
                    totalCountObj.ytdGrossProfit += p.ytdGrossProfit;
                    totalCountObj.mtdBudget += p.mtdBudget;
                    totalCountObj.ytdBudget += p.ytdBudget;
                    totalCountObj.mtdgpp += p.mtdgpp;
                    totalCountObj.ytdgpp += p.ytdgpp;
                });
                totalCountObj.mtdgpp = totalCountObj.mtdgpp / pratRowCount;
                totalCountObj.ytdgpp = totalCountObj.ytdgpp / pratRowCount;
                partsCalculatedList.unshift(totalCountObj);

                let partsTableCalculatedList = [];
                let uniqueBranchList = [];

                parts.forEach((p) => {
                    uniqueBranchList.push({ bn: p.bn, branchID: p.branchID });
                    let objIndex = partsTableCalculatedList.findIndex((q) => q.dc === p.dc);
                    if (objIndex > -1) {
                        partsTableCalculatedList[objIndex].data.push({ ...p });
                    } else {
                        partsTableCalculatedList.push({
                            d: p.d,
                            dc: p.dc,
                            data: [{ ...p }]
                        });
                    }
                });
                uniqueBranchList = [...new Map(uniqueBranchList.map((item) => [item['branchID'], item])).values()];

                let graphData = {
                    uniqueKeys: [],
                    list: []
                };
                parts.forEach((p) => {
                    graphData.uniqueKeys.push({ code: p.dc, name: p.d });
                    let objIndex = graphData.list.findIndex((q) => q.branchID === p.branchID);
                    if (objIndex > -1) {
                        graphData.list[objIndex][`${p.dc}`] = +isFiniteCheck(p.mtdSaleValue - p.mtdBudget);
                    } else {
                        graphData.list.push({
                            branchID: p.branchID,
                            bn: p.bn,
                            [`${p.dc}`]: +isFiniteCheck(p.mtdSaleValue - p.mtdBudget)
                        });
                    }
                });
                graphData.uniqueKeys = [...new Map(graphData.uniqueKeys.map((item) => [item['code'], item])).values()];

                setPartsData((pt) => ({
                    ...pt,
                    partsCalculatedList: partsCalculatedList || [],
                    partsArr: partsTableCalculatedList || [],
                    uniqueBranchList: uniqueBranchList || [],
                    graphData: graphData || [],
                    isPartData: true
                }));
            } else {
                setPartsData((pt) => ({
                    ...pt,
                    isPartData: false
                }));
            }
            if (surcharges.length > 0) {
                setSurchargesData((sr) => ({
                    ...sr,
                    surchargesArr: surcharges,
                    isSurchargesData: true
                }));
            } else {
                setSurchargesData((sr) => ({
                    ...sr,
                    isSurchargesData: false
                }));
            }
            setState((sr) => ({
                ...sr,
                showLoader: false,
                isRecordAvalaible: true
            }));
        } else {
            showToast(res.message);
            setState((sr) => ({
                ...sr,
                showLoader: false,
                isRecordAvalaible: false
            }));
        }
    }, []);

    return (
        <div
            className="reporting-section salesBudgetScreen custom-scroll"
            style={{
                height: 'calc(100vh - 180px)',
                overflow: 'auto',
                // background: '#f5f5f5',
                padding: '10px 10px '
            }}
        >
            {state.showLoader ? (
                <div
                    style={{
                        height: 'calc(100vh - 200px)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <CircularProgress color="secondary" />
                </div>
            ) : (
                <Grid container>
                    {state.isRecordAvalaible ? (
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} style={{ padding: 10 }}>
                                    <AfterSaleYtdMtdSection
                                        label="Yearly Data"
                                        monthlyWIPsUniqueDateList={ytdMtdData.monthlyWIPsUniqueDateList || []}
                                        monthlyWIPsCombinedList={ytdMtdData.monthlyWIPsCombinedList || []}
                                        dailyWIPsCombinedList={ytdMtdData.dailyWIPsCombinedList || []}
                                        dailyWIPsUniqueDateList={ytdMtdData.dailyWIPsUniqueDateList || []}
                                        monthlyWIPsGrapgData={ytdMtdData.monthlyWIPsGrapgData || []}
                                        monthlyWIPsUniqueBranchList={ytdMtdData.monthlyWIPsUniqueBranchList || []}
                                        dailyWIPsGrapgData={ytdMtdData.dailyWIPsGrapgData || []}
                                        dailyWIPsUniqueBranchList={ytdMtdData.dailyWIPsUniqueBranchList || []}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ padding: 10 }}>
                                    <AfterSaleCombined
                                        label="Labour"
                                        sold={state.TotalSold}
                                        taken={state.TotalTaken}
                                        sale={state.TotalSale}
                                        cost={state.TotalCost}
                                        YTDsold={state.YTDTotalSold}
                                        YTDtaken={state.YTDTotalTaken}
                                        YTDsale={state.YTDTotalSale}
                                        YTDcost={state.YTDTotalCost}
                                        MTDTotalBudget={state.MTDTotalBudget}
                                        YTDTotalBudget={state.YTDTotalBudget}
                                        list={state.soldHoursArr || []}
                                        mtdBudget={state.mtdBudget}
                                        ytdBudget={state.ytdBudget}
                                        isHoursSoldBudgetData={state.isHoursSoldBudgetData}
                                    />
                                </Grid>

                                <Grid item xs={12} style={{ padding: 10 }}>
                                    <AfterSaleBranchCardPart
                                        showLoader={partsData.showLoader}
                                        label="Parts"
                                        uniqueBranchList={partsData.uniqueBranchList || []}
                                        partsCalculatedList={partsData.partsCalculatedList || []}
                                        list={partsData.partsArr || []}
                                        graphData={partsData.graphData || []}
                                        isPartData={partsData.isPartData}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={12} md={12} lg={6} style={{ padding: 10 }}>
                                    <AfterSaleSupercharge
                                        label="Surcharges"
                                        list={surchargesData.surchargesArr || []}
                                        partsVOR={state.partsVOR}
                                        isSurchargesData={surchargesData.isSurchargesData}
                                    />
                                </Grid>

                                {isCommunicatorAllowed && (
                                    <Grid item xs={12} md={12} lg={6} style={{ padding: 10 }}>
                                        <AfterSaleJobsDetails label="VOR" partsVOR={state.partsVOR} />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            item
                            xs={12}
                            style={{
                                height: 'calc(100vh - 190px)',
                                overflow: 'auto',
                                background: '#f5f5f5',
                                padding: 10,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                            className="custom-scroll"
                        >
                            <span>Something Went Wrong</span>
                        </Grid>
                    )}
                </Grid>
            )}
        </div>
    );
};

export default AfterSaleReportingDashboard;
